const getUsageStats = ({ year, token, month }, { observer, summaryRepo }) => {
  summaryRepo.getUsageStats(
    { year, token, month },
    {
      success: (response) => {
        const { getUsageStats, getTimeCardsForUser, getUnallocatedAccounts } = response
        if (getUsageStats.error) {
          observer.errorReceivingUsages()
        } else {
          observer.receiveUsages(getUsageStats.data)
        }

        if (getTimeCardsForUser.error) {
          observer.errorReceivingTimeCards()
        } else {
          observer.receiveTimeCards(getTimeCardsForUser.data)
        }

        if (getUnallocatedAccounts.error) {
          observer.errorReceivingUnallocatedAccounts()
        } else {
          observer.receiveUnallocatedAccounts(getUnallocatedAccounts.data)
        }
      },
      failure: () => {
        observer.errorReceivingUsages()
      }
    }
  )
}

export default getUsageStats
