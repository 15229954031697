const getAllUsersUseCase = ({ observer, userRepo }) => {
  userRepo.findAllUsers({
    success: (response) => {
      if (!response.listUsers) {
        observer.errorWhileReceivingUsers()
        return
      }
      observer.receiveUsersInfo(response.listUsers)
    },
    failure: (e) => {
      observer.errorWhileReceivingUsers()
    }
  })
}

export default getAllUsersUseCase
