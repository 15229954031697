import { BudgetStatus } from '../constants/BudgetStatus'
import Roles from '../../config/Roles'

const currentYear = new Date().getFullYear()

const getButtonStatus = (budget, monthsData, selectedYear) => {
  const roles = JSON.parse(localStorage.getItem('roles'))

  if (roles.includes(Roles.PRODUCT_MANAGERS)) {
    const sum = Object.values(monthsData).reduce((a, c) => a + c, 0)

    return (
      sum > budget.amount ||
      (selectedYear === currentYear && [BudgetStatus.PENDING, BudgetStatus.REQUIRE_RESCHEDULE].includes(budget.status))
    )
  } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
    return ![BudgetStatus.PENDING, BudgetStatus.PENDING_UPDATE, BudgetStatus.REQUIRE_RESCHEDULE].includes(budget.status)
  }

  return true
}

export default getButtonStatus
