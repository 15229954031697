function UserMapperRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getUsersAndAccess = (selectedYear, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery($year: Int!) {
                      listUsers {
                        Enabled
                        UserStatus
                        Username
                        Attributes {
                          Name
                          Value
                        }
                      }
                      listUserMapping(year: $year) {
                        error {
                          code
                          message
                          statusCode
                        }
                        data {
                          Username
                          id
                          mappings {
                              endDate
                              startDate
                              reference
                              insertedDate
                              isCurrent
                              access {
                                element1Id
                                element2Id
                                element3Id
                                element4Id
                              }
                            }
                        }
                      }
                    }`,
          variables: {
            year: selectedYear
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.saveUserAccess = ({ username, allocationYear, startDate, access, reference }, { success, failure }) => {
    const query = `mutation ($username: String!, $startDate: String, $reference: String, $effectiveYear: Int!, $access: [UserMappingBreakdown]) {
              createUserMapping(
                  userMapping: {
                  Username: $username,
                  effectiveYear: $effectiveYear,
                  startDate: $startDate,
                  reference: $reference,
                  Access: $access
              })
              {
                data {
                  Username
                  mappings {
                      endDate
                      startDate
                      reference
                      insertedDate
                      isCurrent
                      access {
                        element1Id
                        element2Id
                        element3Id
                        element4Id
                      }
                    }
                  id
                }
                error {
                  statusCode
                  message
                  code
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username,
            effectiveYear: allocationYear,
            reference,
            startDate,
            access
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default UserMapperRepo
