import PropTypes from 'prop-types'
import React from 'react'
import './radio_toggle_switch.scss'

const RadioToggleSwitch = ({ toggleSwitchOptions, name, onChange, selectedOption }) => {
  return (
    <div className="radio-toggle-switch-container">
      <div role="radiogroup" className="radio-toggle-switch">
        {toggleSwitchOptions.map((option, index) => {
          return (
            <span key={index}>
              <input
                className="display-none"
                key={index}
                type="radio"
                id={option.value}
                name={name}
                value={option.value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                checked={selectedOption === option.value}
              />
              {/* eslint-disable-next-line */}
              <label htmlFor={option.value}>{option.label}</label>
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default RadioToggleSwitch

RadioToggleSwitch.propTypes = {
  toggleSwitchOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.string
}
