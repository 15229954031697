import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import dollarPriceFormatter from '../../formatters/dollar_price_formatter'
import Roles from '../../config/Roles'
import getVarianceToDate from 'Budgets/helpers/getVarianceTodate'
import SemiCircularProgressbar from 'components/common/SemiCircularProgressbar/SemiCircularProgressbar'
import { BudgetStatusLabel } from 'Budgets/constants/BudgetStatus'

const currentMonth = new Date().getMonth()

const getPlannedSpendMonth = (budget, month = currentMonth) => {
  const plannedSpendMonth = budget.budgets[`_${month}`]
  return dollarPriceFormatter(plannedSpendMonth)
}

const getSpendMonth = (budget, month = currentMonth) => {
  const plannedSpendMonth = budget.actuals[`_${month}`]
  return dollarPriceFormatter(plannedSpendMonth)
}

const BudgetGauge = ({ budget, selectedBudget, isSelected, isGrouped, isMonthly }) => {
  const { percentage, usedYTD, amount } = getUsagesDetails({ budget, isMonthly })
  return (
    <div className={`budget ${isSelected ? 'selected' : ''} ${budget.status}`}>
      <div className={`status-label ${budget.status}`}>{BudgetStatusLabel[budget.status]}</div>

      <Row className="pt-2">
        <Col xs={12}>
          <div className="divisions">{getElementDetails({ isGrouped, budget })}</div>
        </Col>
        <div xs={12} className="d-flex justify-content-center">
          <SemiCircularProgressbar radio="80" percent={percentage} circledContent />
        </div>
        <div className="usage-in-chart">{dollarPriceFormatter(usedYTD)}</div>

        <div className="budget-info d-flex justify-content-between">
          <div className="divisions">
            <div>
              <span className="header">{isMonthly ? `Planned Spend` : `Available Budget`}</span>
              <br />
              <span className="details">
                {isMonthly ? getPlannedSpendMonth(budget) : dollarPriceFormatter(amount - usedYTD)}
              </span>
            </div>
          </div>

          <div className="divisions">
            <div className="w-100 text-end">
              <span className="header">{isMonthly ? `Spend Last Month` : 'Variance'}</span>
              <br />
              <span className="details">
                {isMonthly ? getSpendMonth(budget, currentMonth - 1) : getVarianceToDate(budget, usedYTD)}
              </span>
            </div>
          </div>
        </div>

        <Col xs={12}>
          <Row>
            <Col />
            <Col>
              <Button
                disabled={isSelected}
                variant={isSelected ? 'secondary' : 'primary'}
                onClick={() => selectedBudget(budget)}
              >
                Details
              </Button>
            </Col>
            <Col />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default BudgetGauge

const getElementDetails = ({ budget, isGrouped }) => {
  const roles = JSON.parse(localStorage.getItem('roles'))

  let firstElementDisplay = 'Portfolio'
  let firstElementValue = budget.element3
  let secondElementDisplay = 'Product'
  let secondElementValue = budget.element4

  if (isGrouped && roles.includes(Roles.PORTFOLIO_MANAGERS)) {
    firstElementDisplay = 'Department'
    firstElementValue = budget.items[0].element2
    secondElementDisplay = 'Portfolio'
    secondElementValue = budget.items[0].element3
  }
  return (
    <>
      <div>
        <span className="header">{firstElementDisplay}</span>
        <br />
        <span className="details">{firstElementValue}</span>
      </div>
      <div>
        <span className="header">{secondElementDisplay}</span>
        <br />
        <span className="details">{secondElementValue}</span>
      </div>
    </>
  )
}

const getUsagesDetails = ({ budget, isMonthly }) => {
  if (!isMonthly) {
    return {
      percentage: Math.floor((budget.usedYTD / budget.amount) * 100),
      amount: budget.amount,
      usedYTD: budget.usedYTD
    }
  }

  let usedYTD = 0
  let amount = 0
  const currentMonth = new Date().getMonth()
  if (budget.items) {
    budget.items.forEach((item) => {
      usedYTD = usedYTD + (item.actuals[`_${currentMonth}`] || 0)
      amount = amount + (item.budgets[`_${currentMonth}`] || 0)
    })
  } else {
    usedYTD = budget.actuals[`_${currentMonth}`] || 0
    amount = budget.budgets[`_${currentMonth}`] || 0
  }

  return {
    percentage: amount === 0 ? amount : Math.floor((usedYTD / amount) * 100),
    amount,
    usedYTD
  }
}
