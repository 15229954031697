const submitBudget = (inputParams, { budgetsRepo, observer }) => {
  if (inputParams.executionId) {
    budgetsRepo.reSubmitBudget(inputParams, {
      success: (response) => {
        observer.successfullyCreatedBudget()
      },
      failure: () => {
        observer.errorWhileCreatingBudget()
      }
    })
  } else {
    budgetsRepo.createBudget(inputParams, {
      success: (response) => {
        observer.successfullyCreatedBudget()
      },
      failure: () => {
        observer.errorWhileCreatingBudget()
      }
    })
  }
}

export default submitBudget
