import { BudgetStatus } from 'Budgets/constants/BudgetStatus'
import getVarianceToDate from 'Budgets/helpers/getVarianceTodate'
import getIsValidBudgetData from 'Budgets/helpers/validBudgetData'
import CircularProgressbar from 'components/common/CircularProgressbar/CircularProgressbar'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import React from 'react'
import { Card } from 'react-bootstrap'

const currentMonth = new Date().getMonth()

const getPortfolioNames = (budget) => {
  let deparmentName = budget.element3
  let portfolioName = budget.element4

  if (!deparmentName && !portfolioName && budget.items && budget.items.length > 0) {
    deparmentName = budget.items[0].element2
    portfolioName = budget.items[0].element3
  }
  return {
    deparmentName,
    portfolioName
  }
}

const getColorGauge = (percent) => {
  if (percent < 25) {
    return 'green'
  } else if (percent < 60) {
    return 'yellow'
  } else if (percent < 90) {
    return 'orange'
  }

  return 'red'
}

const getSpend = (budget, month) => {
  let spend = 0
  if (budget.items) {
    budget.items.forEach((item) => {
      spend += item.actuals[`_${month}`] || 0
    })
  } else {
    spend = budget.actuals[`_${month}`] || 0
  }
  return spend
}

const getApprovedBudget = (budget) => {
  let approvedBudget = 0
  if (budget.items) {
    budget.items.forEach((item) => {
      const budgetValues = Object.values(item.budgets)
      for (let i = currentMonth; i < 12; i++) {
        approvedBudget += budgetValues[i]
      }
    })
  }
  return approvedBudget
}

const getPortfolioData = (budget, isMonthly) => {
  if (!isMonthly) {
    return {
      percentage: Math.floor((budget.usedYTD / budget.amount) * 100) || 0,
      amount: budget.amount,
      usedYTD: budget.usedYTD
    }
  }

  let usedYTD = 0
  let amount = 0

  if (budget.items) {
    budget.items.forEach((item) => {
      usedYTD = usedYTD + (item.actuals[`_${currentMonth}`] || 0)
      amount = amount + (item.budgets[`_${currentMonth}`] || 0)
    })
  } else {
    usedYTD = budget.actuals[`_${currentMonth}`] || 0
    amount = budget.budgets[`_${currentMonth}`] || 0
  }

  return {
    percentage: Math.floor((usedYTD / amount) * 100) || 0,
    amount,
    usedYTD
  }
}

const PortfolioCard = ({ budget, isMonthly, setSelectedBudget, isGrouped }) => {
  const hasBudgetPlan = getIsValidBudgetData(budget.items)
  const { deparmentName, portfolioName } = getPortfolioNames(budget, isGrouped)

  if (!hasBudgetPlan) {
    return (
      <Card className="portfolio-card" key={budget.trend}>
        <Card.Body>
          <div className="portfolio-header">
            <div>
              <Card.Subtitle>Department</Card.Subtitle>
              <h5>{deparmentName}</h5>
            </div>
            <div className="text-end">
              <Card.Subtitle>Portfolio</Card.Subtitle>
              <h5>{portfolioName}</h5>
            </div>
          </div>

          <div className="alert alert-info mt-3">Requires Budget Plan</div>
        </Card.Body>
      </Card>
    )
  }

  const { percentage, usedYTD, amount } = getPortfolioData(budget, isMonthly)

  const color = getColorGauge(percentage)

  const isHighlighted = !budget.items
    .map((item) => item.status)
    .every((status) => status === BudgetStatus.APPROVED || status === BudgetStatus.NOT_INITIATED)

  return (
    <Card className={`portfolio-card ${isHighlighted ? 'portfolio-card-highlighted' : ''}`} key={budget.trend}>
      <Card.Body>
        <div className="portfolio-header">
          <div>
            <Card.Subtitle>Department</Card.Subtitle>
            <h5>{deparmentName}</h5>
          </div>
          <div className="text-end">
            <Card.Subtitle>Portfolio</Card.Subtitle>
            <h5>{portfolioName}</h5>
          </div>
        </div>

        <Card className={`card-outline-${color} mb-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <CircularProgressbar radio="50" percent={percentage} color={color} />
            <div className="text-end">
              <Card.Subtitle>Spend</Card.Subtitle>
              <h2>{dollarPriceFormatter(usedYTD)}</h2>
              <Card.Subtitle>Planned</Card.Subtitle>
              <h2>{dollarPriceFormatter(amount)}</h2>
            </div>
          </Card.Body>
        </Card>

        <Card className={`card-outline-${color} mb-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div>
              <h5 className="text-gray mb-1">Variance in spend</h5>
              <h2>{getVarianceToDate(budget, isMonthly)}</h2>
            </div>
            <div className="text-end">
              {isMonthly ? (
                <>
                  <Card.Subtitle>Spend Last Month</Card.Subtitle>
                  <h4>{dollarPriceFormatter(getSpend(budget, currentMonth - 1))}</h4>
                  <Card.Subtitle>Last Period Spend</Card.Subtitle>
                  <h4></h4>
                </>
              ) : (
                <>
                  <Card.Subtitle>Available Budget</Card.Subtitle>
                  <h4>{dollarPriceFormatter(amount - usedYTD)}</h4>
                  <Card.Subtitle>EOY Planned</Card.Subtitle>
                  <h4>{dollarPriceFormatter(getApprovedBudget(budget))}</h4>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
        <div className="text-center mt-4 mb-3">
          <button className="btn btn-app rounded" type="button" onClick={() => setSelectedBudget(budget)}>
            Details
          </button>
        </div>
      </Card.Body>
    </Card>
  )
}

export default PortfolioCard
