function UserRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.findAllUsers = ({ success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery {
                          listUsers {
                            Enabled
                            UserStatus
                            Username
                            Attributes {
                              Name
                              Value
                            }
                          }
                        }`
        }
      },
      {
        success: (response) => success(response.data.data),
        failure: (error) => failure(error)
      }
    )
  }

  this.inviteUser = ({ username, email, role }, { success, failure }) => {
    const query = `mutation ($username: String!, $email: String!, $role: String!) {
              createUser(
                  user: {
                  username: $username,
                  email: $email,
                  role: $role
              })
              {
                Attributes {
                  Name
                  Value
                }
                Enabled
                UserStatus
                Username
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username,
            email,
            role
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default UserRepo
