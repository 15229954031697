const getAllAccounts = ({ existingAllocations, allocationYear }, { observer, accountMapperRepo }) => {
  accountMapperRepo.findAllAccounts({
    success: (accounts) => {
      const filteredAccounts = []
      accounts.forEach((item) => {
        const existingSelectedAccount = existingAllocations.find((el) => el.accountId === item.accountId)

        !existingSelectedAccount && filteredAccounts.push(item)
      })

      observer.accountsReceived(filteredAccounts, accounts)
    },
    failure: (e) => {
      observer.errorReceivingAccounts()
    }
  })
}

export default getAllAccounts
