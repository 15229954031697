import { Alert, Button, Form } from 'react-bootstrap'
import React, { useState } from 'react'
import loginUserUseCase from '../use-cases/login-user-use-case'
import moment from 'moment'
import Routes from '../../routes'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import Roles from 'config/Roles'

const UsernamePassword = ({
  username,
  setUsername,
  setChallengePasswordSession,
  setChangePasswordChallenge,
  repoFactory
}) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [password, setPassword] = useState(null)
  const history = useHistory()

  const handleLogin = (e) => {
    e.preventDefault()
    setIsLoading(true)
    loginUserUseCase(
      {
        username,
        password
      },
      {
        authRepo: repoFactory.authRepo(),
        observer: {
          loginSuccess: ({ authToken, refreshToken, expires, role }) => {
            setError(false)
            localStorage.setItem('username', username)
            localStorage.setItem('authToken', authToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('expirationTime', JSON.stringify(moment.now() + expires * 1000))
            localStorage.setItem('roles', JSON.stringify([role]))
            setChangePasswordChallenge(false)
            setIsLoading(false)
            if (role === Roles.ADMINS) {
              history.push(Routes.USER.PATH)
            } else {
              history.push(Routes.DASHBOARD.PATH)
            }
          },
          loginChangePasswordChallenge: (session) => {
            setError(false)
            setChallengePasswordSession(session)
            setIsLoading(false)
            setChangePasswordChallenge(true)
          },
          loginFailed: () => {
            setChangePasswordChallenge(false)
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  return (
    <>
      {error && <Alert variant={'danger'}>Unable to login, please check your credentials and try again</Alert>}
      {isLoading && <LoadingSpinner />}
      <Form>
        <Form.Group className="mb-3" controlId="formBasicUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="username"
            placeholder="Enter username"
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={!(username && password) || isLoading} onClick={handleLogin}>
          Submit
        </Button>
      </Form>
    </>
  )
}

export default UsernamePassword
