import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'

import { getFormattedRow, isFutureMonth } from 'utils/reports-helper'

const columnHelper = createColumnHelper()

const getVarianceTemplate = (monthData, year, monthIndex) => {
  try {
    return (
      <div className="variance-row-content">
        <h6>
          <span className={monthData.variance < 0 ? 'text-danger' : monthData.variance > 0 ? 'text-success' : ''}>
            {getFormattedRow(Math.abs(monthData.variance), year, monthIndex)}
          </span>{' '}
          <span className={`y2d ${monthData.y2d < 0 ? 'text-danger' : monthData.y2d > 0 ? 'text-success' : ''}`}>
            ({dollarPriceFormatter(Math.abs(monthData.y2d))})
          </span>
        </h6>
        <div className="text-end">
          <h6>{getFormattedRow(monthData.actual, year, monthIndex)}</h6>
          <h6>{getFormattedRow(monthData.budget, year, monthIndex)}</h6>
        </div>
      </div>
    )
  } catch (e) {
    return <></>
  }
}

const getVarianceRowContent = (props, year, monthIndex) => {
  const { row } = props
  const { months } = row.original

  const monthData = months[`_${monthIndex}`]

  if (isFutureMonth(year, monthIndex)) {
    return <div className="variance-row-content"></div>
  }

  return getVarianceTemplate(monthData, year, monthIndex)
}

const getTotals = (items, year, monthIndex) => {
  if (isFutureMonth(year, monthIndex)) {
    return <div className="variance-row-content"></div>
  }

  const totalMonthData = {
    actual: 0,
    budget: 0,
    variance: 0,
    y2d: 0
  }

  for (const item of items) {
    const { months } = item
    const monthData = months[`_${monthIndex}`]
    if (monthData) {
      totalMonthData['actual'] += monthData.actual
      totalMonthData['budget'] += monthData.budget
      totalMonthData['variance'] += monthData.variance
      totalMonthData['y2d'] += monthData.y2d
    }
  }

  return getVarianceTemplate(totalMonthData, year, monthIndex)
}

const getVarianceReportColumns = (year, items) => {
  return [
    columnHelper.group({
      header: 'Financial Scope',
      columns: [
        columnHelper.accessor('businessUnit', {
          header: () => 'Business Unit'
        }),
        columnHelper.accessor('deparment', {
          header: () => 'Department'
        }),
        columnHelper.accessor('portafolio', {
          header: () => 'Portfolio'
        }),
        columnHelper.accessor('product', {
          header: () => 'Product'
        })
      ]
    }),
    columnHelper.group({
      header: 'Period',
      columns: [
        columnHelper.accessor((row) => row.months._0, {
          id: 'Jan',
          cell: (props) => getVarianceRowContent(props, year, 0),
          aggregatedCell: () => getTotals(items, year, 0)
        }),
        columnHelper.accessor((row) => row.months._1, {
          id: 'Feb',
          cell: (props) => getVarianceRowContent(props, year, 1),
          aggregatedCell: () => getTotals(items, year, 1)
        }),
        columnHelper.accessor((row) => row.months._2, {
          id: 'Mar',
          cell: (props) => getVarianceRowContent(props, year, 2),
          aggregatedCell: () => getTotals(items, year, 2)
        }),
        columnHelper.accessor((row) => row.months._3, {
          id: 'Apr',
          cell: (props) => getVarianceRowContent(props, year, 3),
          aggregatedCell: () => getTotals(items, year, 3)
        }),
        columnHelper.accessor((row) => row.months._4, {
          id: 'May',
          cell: (props) => getVarianceRowContent(props, year, 4),
          aggregatedCell: () => getTotals(items, year, 4)
        }),
        columnHelper.accessor((row) => row.months._5, {
          id: 'Jun',
          cell: (props) => getVarianceRowContent(props, year, 5),
          aggregatedCell: () => getTotals(items, year, 5)
        }),
        columnHelper.accessor((row) => row.months._6, {
          id: 'Jul',
          cell: (props) => getVarianceRowContent(props, year, 6),
          aggregatedCell: () => getTotals(items, year, 6)
        }),
        columnHelper.accessor((row) => row.months._7, {
          id: 'Aug',
          cell: (props) => getVarianceRowContent(props, year, 7),
          aggregatedCell: () => getTotals(items, year, 7)
        }),
        columnHelper.accessor((row) => row.months._8, {
          id: 'Sep',
          cell: (props) => getVarianceRowContent(props, year, 8),
          aggregatedCell: () => getTotals(items, year, 8)
        }),
        columnHelper.accessor((row) => row.months._9, {
          id: 'Oct',
          cell: (props) => getVarianceRowContent(props, year, 9),
          aggregatedCell: () => getTotals(items, year, 9)
        }),
        columnHelper.accessor((row) => row.months._10, {
          id: 'Nov',
          cell: (props) => getVarianceRowContent(props, year, 10),
          aggregatedCell: () => getTotals(items, year, 10)
        }),
        columnHelper.accessor((row) => row.months._11, {
          id: 'Dec',
          cell: (props) => getVarianceRowContent(props, year, 11),
          aggregatedCell: () => getTotals(items, year, 11)
        })
      ]
    })
  ]
}

export default getVarianceReportColumns
