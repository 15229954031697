import React, { useEffect } from 'react'

const BusinessIntelligence = () => {
  useEffect(() => {
    function resizeIFrameToFitContent(iFrame) {
      iFrame.width = iFrame.contentWindow.document.body.scrollWidth
      iFrame.height = iFrame.contentWindow.document.body.scrollHeight
    }

    window.addEventListener('DOMContentLoaded', function (e) {
      const iFrame = document.getElementById('quick-sight-iframe')
      resizeIFrameToFitContent(iFrame)

      // or, to resize all iframes:
      const iframes = document.querySelectorAll('iframe')
      for (let i = 0; i < iframes.length; i++) {
        resizeIFrameToFitContent(iframes[i])
      }
    })

    return () => {
      window.removeEventListener('DOMContentLoaded')
    }
  }, [])

  return (
    <iframe
      title="Quick site dashboards"
      src={process.env.REACT_APP_QUICK_SITE_URL}
      width={'100%'}
      height={'800px'}
      id="quick-sight-iframe"
    >
      TBD
    </iframe>
  )
}

export default BusinessIntelligence
