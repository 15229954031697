function AccountMapperRepoNew({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.reFetchAccounts = ({ success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery {
                          updateAccountsWithOrganizationsInfo {
                            new {
                              accountId
                              accountName
                              id
                              masterRefId
                            }
                            updated {
                              accountId
                              accountName
                              id
                              masterRefId
                            }
                          }
                        }`
        }
      },
      {
        success: (response) => success(response.data.data.updateAccountsWithOrganizationsInfo),
        failure: (error) => failure(error)
      }
    )
  }

  this.findAllAccounts = ({ success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery {
                          listAccounts {
                            accountId
                            accountName
                            createdDate
                            email
                            status
                            id
                            masterRefId
                          }
                        }`
        }
      },
      {
        success: (response) => success(response.data.data.listAccounts),
        failure: (error) => failure(error)
      }
    )
  }

  this.listAccountMapping = (selectedYear, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery($year: Int!) {
                      listAccountMapping(year: $year) {
                        existingMappings {
                          accountId
                          id
                          mappings {
                              startDate
                              accountType
                              endDate
                              reference
                              insertedDate
                              allocations {
                                element1Id
                                element2Id
                                element3Id
                                element4Id
                                percentage
                              }
                            }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`,
          variables: {
            year: selectedYear
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.saveOrUpdateAllocation = (
    { accountId, accountType, startDate, reference, allocationYear, allocations },
    { success, failure }
  ) => {
    const query = `mutation ($accountId: String!, $accountType: String!, $allocationYear: Int!, $startDate: String!, $reference: String, $allocations: [AccountMappingBreakdown]) {
                      createAccountMapping(accountMapping: {accountId: $accountId,
                                      allocations: $allocations,
                                      accountType: $accountType,
                                      allocationYear: $allocationYear,
                                      reference: $reference,
                                      startDate: $startDate}) {
                        error {
                          code
                          message
                          statusCode
                        }
                        data {
                          accountId
                          id
                          mappings {
                              startDate
                              accountType
                              endDate
                              reference
                              insertedDate
                              allocations {
                                element1Id
                                element2Id
                                element3Id
                                element4Id
                                percentage
                              }
                            }
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            accountId,
            accountType,
            allocationYear,
            reference,
            startDate,
            allocations: allocations
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default AccountMapperRepoNew
