const updateTimeCardStatus = ({ token, id, status }, { observer, summaryRepo }) => {
  summaryRepo.updateTimeCardStatus(
    { token, id, status },
    {
      success: (response) => {
        if (response.error) {
          observer.errorUpdatingTimeCard()
          return
        }

        observer.receiveSuccessfulTimeCardUpdate(response)
      },
      failure: () => {
        observer.errorUpdatingTimeCard()
      }
    }
  )
}

export default updateTimeCardStatus
