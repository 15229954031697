function BudgetsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getAllBudgets = ({ year, token }, { success, failure }) => {
    const query = `query ($token: String!, $year: Int!) {
                          getAllBudgets(budgetParams: {token: $token, year: $year}) {
                            data {
                                usages {
                                    element1
                                    element2
                                    element3
                                    element4
                                    values {
                                      _0
                                      _1
                                      _2
                                      _3
                                      _4
                                      _5
                                      _6
                                      _7
                                      _8
                                      _9
                                      _10
                                      _11
                                    }
                                  }
                              assignedBudgets {
                                element1
                                element2
                                element3
                                element4
                                startDate
                                endDate
                                amount
                              }
                              approvedBudgets {
                                approvedBy
                                element1
                                element2
                                element3
                                element4
                                requestedBy
                                year
                                budgets {
                                  _0
                                  _1
                                  _2
                                  _3
                                  _4
                                  _5
                                  _6
                                  _7
                                  _8
                                  _9
                                  _10
                                  _11
                                }
                                estimatedBudgets {
                                  _0
                                  _1
                                  _2
                                  _3
                                  _4
                                  _5
                                  _6
                                  _7
                                  _8
                                  _9
                                  _10
                                  _11
                                }
                              }
                              pendingBudgets {
                                element1
                                element2
                                element3
                                element4
                                executionId
                                requestedBy
                                reviewedBy
                                year
                                budgets {
                                  _0
                                  _1
                                  _2
                                  _3
                                  _4
                                  _5
                                  _6
                                  _7
                                  _8
                                  _9
                                  _10
                                  _11
                                }
                                estimatedBudgets {
                                  _0
                                  _1
                                  _2
                                  _3
                                  _4
                                  _5
                                  _6
                                  _7
                                  _8
                                  _9
                                  _10
                                  _11
                                }
                                status
                              }
                            }
                            error {
                              code
                              message
                              statusCode
                            }
                          }
                        }
`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const { getAllBudgets } = response.data.data
          success(getAllBudgets)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createBudget = (
    { year, token, element1, element2, element3, element4, budgets, isReschedule },
    { success, failure }
  ) => {
    const query = `mutation ($token: String!, $year: Int!, $budgets: Budgets!, $element1: String!, $element2: String!, $element3: String!, $element4: String!, $isReschedule: Boolean!) {
              createBudget(createParams: {budgets: $budgets, element1: $element1, element2: $element2, element3: $element3, element4: $element4, token: $token, year: $year, isReschedule: $isReschedule}) {
                element1
                element2
                element3
                element4
                executionId
                requestedBy
                reviewedBy
                status
                year
                budgets {
                  _0
                  _1
                  _2
                  _3
                  _4
                  _5
                  _6
                  _7
                  _8
                  _9
                  _10
                  _11
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            element1,
            element2,
            element3,
            element4,
            budgets,
            isReschedule
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.reSubmitBudget = ({ token, budgets, executionId }, { success, failure }) => {
    const query = `mutation ($token: String!, $budgets: Budgets!, $executionId: String!) {
              updateBudget(updateParams: {budgets: $budgets, executionId: $executionId, token: $token}) {
                element1
                element2
                element3
                element4
                executionId
                requestedBy
                reviewedBy
                status
                year
                budgets {
                  _0
                  _1
                  _2
                  _3
                  _4
                  _5
                  _6
                  _7
                  _8
                  _9
                  _10
                  _11
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            executionId,
            budgets
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.submitReview = ({ token, executionId, status }, { success, failure }) => {
    const query = `mutation ($token: String!, $status: String!, $executionId: String!) {
              reviewBudget(reviewParams: {executionId: $executionId, token: $token, status: $status}) {
                element1
                element2
                element3
                element4
                executionId
                requestedBy
                reviewedBy
                status
                year
                budgets {
                  _0
                  _1
                  _2
                  _3
                  _4
                  _5
                  _6
                  _7
                  _8
                  _9
                  _10
                  _11
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            executionId,
            status
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default BudgetsRepo
