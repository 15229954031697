import { BudgetStatus } from '../../constants/BudgetStatus'
import Roles from '../../../config/Roles'

const assignPendingAndApprovedToAllocated = (
  { assignedBudgets, approvedBudgets, pendingBudgets, usages },
  role,
  isGrouped
) => {
  if (!assignedBudgets) {
    return assignedBudgets
  }

  if (assignedBudgets.length === 0) {
    return assignedBudgets
  }

  const response = []

  assignedBudgets.forEach((budget) => {
    const filteredApprovedBudgets = approvedBudgets.find(
      (item) =>
        item.element1 === budget.element1 &&
        item.element2 === budget.element2 &&
        item.element3 === budget.element3 &&
        item.element4 === budget.element4
    )
    const filteredPendingBudgets = pendingBudgets.find(
      (item) =>
        item.element1 === budget.element1 &&
        item.element2 === budget.element2 &&
        item.element3 === budget.element3 &&
        item.element4 === budget.element4
    )

    const filteredActuals = usages.find(
      (item) =>
        item.element1 === budget.element1 &&
        item.element2 === budget.element2 &&
        item.element3 === budget.element3 &&
        item.element4 === budget.element4
    )

    if (filteredPendingBudgets) {
      budget.status = filteredPendingBudgets.status
      budget.reviewedBy = filteredPendingBudgets.reviewedBy
      budget.requestedBy = filteredPendingBudgets.requestedBy
      budget.budgets = filteredPendingBudgets.budgets
      budget.estimatedBudgets = filteredPendingBudgets.estimatedBudgets
      budget.executionId = filteredPendingBudgets.executionId
    } else if (filteredApprovedBudgets) {
      budget.status = BudgetStatus.APPROVED
      budget.reviewedBy = filteredApprovedBudgets.approvedBy
      budget.requestedBy = filteredApprovedBudgets.requestedBy
      budget.budgets = filteredApprovedBudgets.budgets
      budget.estimatedBudgets = filteredApprovedBudgets.estimatedBudgets
    } else {
      budget.status = BudgetStatus.NOT_INITIATED
      budget.budgets = {}
      budget.estimatedBudgets = {}
    }

    if (filteredActuals) {
      budget.actuals = filteredActuals.values
    } else {
      budget.actuals = {}
    }

    const usedAmountsArray = Object.values(budget.actuals)
    let usedSum = usedAmountsArray.length > 0 ? usedAmountsArray.reduce((usedSum, x) => usedSum + x) : 0
    budget.usedYTD = usedSum

    response.push(budget)
  })

  return groupBy(response, role, isGrouped)
}

export default assignPendingAndApprovedToAllocated

const groupBy = (list, role, isGrouped) => {
  if (!isGrouped) {
    return list
  }

  const groupMap = {}

  let groupKey = 'element4'
  if (role.includes(Roles.PORTFOLIO_MANAGERS)) {
    groupKey = 'element3'
  } else if (role.includes(Roles.DEPARTMENT_MANAGERS)) {
    groupKey = 'element2'
  } else if (role.includes(Roles.BU_MANAGERS)) {
    groupKey = 'element1'
  } else if (role.includes(Roles.FINANCIAL_ADMINS)) {
    groupKey = 'element1'
  }

  list.forEach((item) => {
    if (!groupMap[item[groupKey]]) {
      groupMap[item[groupKey]] = {
        usedYTD: item.usedYTD,
        amount: item.amount,
        items: [item]
      }
    } else {
      groupMap[item[groupKey]].usedYTD += item.usedYTD
      groupMap[item[groupKey]].amount += item.amount
      groupMap[item[groupKey]].items.push(item)
    }
  })

  return Object.values(groupMap)
}
