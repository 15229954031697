import React from 'react'
import { Redirect } from 'react-router-dom'
import Routes from '../../routes'

const Logout = () => {
  localStorage.clear()
  return <Redirect to={Routes.LOGIN.PATH} />
}

export default Logout
