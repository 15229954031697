const getPeriodCardsForUser = ({ year, token }, { observer, timePeriodsRepo }) => {
  timePeriodsRepo.getPeriodCardsForUser(
    { year, token },
    {
      success: (response) => {
        const { error, data } = response
        if (error) {
          observer.errorReceivingPeriodCards()
          return
        }

        observer.receivePeriodCards(data)
      },
      failure: () => {
        observer.errorReceivingPeriodCards()
      }
    }
  )
}

export default getPeriodCardsForUser
