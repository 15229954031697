import { Route } from 'react-router-dom'
import Login from '../Authentication/components/Login'
import Register from '../Authentication/components/Register'
import Logout from '../Authentication/components/Logout'
import Routes from '../routes'

const getPublicRoutes = ({ ...rest }) => {
  return [
    <Route
      exact
      key={Routes.REGISTER.PATH}
      path={Routes.REGISTER.PATH}
      render={(props) => <Register {...rest} {...props} />}
    />,
    <Route exact key={Routes.LOGIN.PATH} path={Routes.LOGIN.PATH} render={(props) => <Login {...rest} {...props} />} />,
    <Route
      exact
      key={Routes.LOGOUT.PATH}
      path={Routes.LOGOUT.PATH}
      render={(props) => <Logout {...rest} {...props} />}
    />
  ]
}

export default getPublicRoutes
