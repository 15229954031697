import Roles from '../../../../config/Roles'

const filterUsersNoBudget = ({ allUsers, usersAndTheirBudget }) => {
  if (!usersAndTheirBudget.data) {
    return { usersWithNoBudget: allUsers }
  }

  const usersWithNoBudget = []
  const usersWithBudget = []

  allUsers.forEach((user) => {
    const isFinancialAdmin =
      user.Attributes.find((attribute) => attribute.Name === 'custom:role')?.Value === Roles.FINANCIAL_ADMINS

    if (!isFinancialAdmin) {
      const selectedUsersBudget = usersAndTheirBudget.data.filter(
        (userBudget) => userBudget.Username.toUpperCase() === user.Username.toUpperCase()
      )

      if (selectedUsersBudget.length === 0) {
        usersWithNoBudget.push(user)
      } else {
        usersWithBudget.push(selectedUsersBudget[0])
      }
    }
  })

  return { usersWithNoBudget, usersWithBudget }
}

export default filterUsersNoBudget
