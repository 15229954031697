import React from 'react'
import { Card } from 'react-bootstrap'

import './Portfolios.scss'
import PortfolioCard from './PortfolioCard'
import Slider from 'react-slick'

const Portafolios = ({ budgets = [], setSelectedBudget, isGrouped, isMonthly }) => {
  const slidesToShow = 3

  const settings = {
    ...(budgets.length < slidesToShow && { className: 'slick-align-left' }),
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1
  }

  return (
    <>
      {budgets.length > 0 && (
        <Card className="portafolios section-gray mb-3">
          <Card.Body>
            <h3 className="budget-subtitle">Portfolio</h3>
            <Slider {...settings}>
              {budgets.map((budget, i) => (
                <PortfolioCard
                  key={`portafolio-card-${i}`}
                  budget={budget}
                  isMonthly={isMonthly}
                  setSelectedBudget={setSelectedBudget}
                  isGrouped={isGrouped}
                />
              ))}
            </Slider>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default Portafolios
