import twoDecimalsFormatter from './two_decimals_formatter'

const formatPercent = (percent) => {
  const noCommaPercent = percent.toString().replace(/,/g, '')
  if (!percent || !isFinite(noCommaPercent)) {
    return '--'
  }
  return percent + '%'
}

const percentFormatter = (decimal) => formatPercent(twoDecimalsFormatter(decimal))

const timesHundredPercentFormatter = (decimal) => {
  if (decimal === '' || decimal === null || isNaN(decimal)) {
    return '--'
  }
  return formatPercent(twoDecimalsFormatter(decimal * 100))
}

const absolutePercentFormatter = (decimal) => formatPercent(twoDecimalsFormatter(Math.abs(decimal)))

const percentWithoutZeroDecimalsFormatter = (decimal) => formatPercent(decimalsFormatter(decimal, 0, 2))

const percentPostFixFormatter = (value) => {
  return formatPercent(value)
}

export {
  percentPostFixFormatter,
  absolutePercentFormatter,
  percentFormatter,
  percentWithoutZeroDecimalsFormatter,
  timesHundredPercentFormatter
}

const decimalsFormatter = (number, minimumFractionDigits, maximumFractionDigits) => {
  if (number === '' || number === null || isNaN(number)) {
    return '--'
  }

  const formattedPrice = Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  }).format(number)

  const zeroValueWithZeroDecimals = '0.' + new Array(minimumFractionDigits).fill('0').join('')

  return formattedPrice === '-' + zeroValueWithZeroDecimals ? zeroValueWithZeroDecimals : formattedPrice
}
