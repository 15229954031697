// TODO: The logic of this method should be changed or remove the method
const getIsValidBudgetData = (budgetsData, isGrouped = false) => {
  if (!isGrouped) {
    for (const data of budgetsData) {
      const { actuals, budgets } = data

      if (!actuals || !budgets) {
        return false
      }
    }
    return true
  } else {
    for (const data of budgetsData) {
      for (const innerData of data.items) {
        const { actuals, budgets } = innerData
        if (!actuals || !budgets) {
          return false
        }
      }
    }
    return true
  }
}

export default getIsValidBudgetData
