export const TimeCardStatus = {
  OPEN: 'Open',
  RESOLVE: 'Resolve',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  DISPUTE: 'Dispute',
  ACCEPT: 'Accept'
}

export const Status = {
  Open: 'Open',
  Accept: 'Submitted',
  Dispute: 'Dispute',
  Approve: 'Approved',
  Resolve: 'Resolved',
  Reject: 'Rejected'
}
