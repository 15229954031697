import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, useSortBy } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import getColumns from './AllowedAllocationsColumns'

const AllowedAllocations = ({ accounts, actualsData, inputMonth, inputYear }) => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [sorting, setSorting] = useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false
        }
      ]
    },
    useSortBy
  })

  useEffect(() => {
    setData([...actualsData])
    setColumns(getColumns(actualsData[0], inputMonth, inputYear))
  }, [actualsData, inputMonth])

  const getAccountName = (accountId) => accounts[accountId]?.accountName

  return (
    <div className="table-responsive" style={{ maxHeight: '300px' }}>
      <table className="table table-hover table-striped">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === 'percentage' ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {`${row.original.startDate} to ${row.original.endDate}`}
                          </Tooltip>
                        )}
                      >
                        <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>
                      {cell.column.id === 'accountId' ? (
                        <>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                {getAccountName(cell.getValue())}
                              </Tooltip>
                            )}
                          >
                            <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                      )}
                    </>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AllowedAllocations
