import filterUsersNoBudget from './converters/filter_users_no_budget'
import filterNonAdminUsers from './converters/filter_non_admin_users'

const getUserElements = ({ allocationYear, roles, roleLevels }, { observer, userMapperRepo }) => {
  userMapperRepo.getUsersAndAccess(allocationYear, {
    success: (response) => {
      const { listUsers: allUsers, listUserMapping: usersAndTheirBudget } = response
      if (usersAndTheirBudget.error) {
        observer.errorGettingData()
        return
      }

      const filteredNonAdminUsers = filterNonAdminUsers(roles, allUsers)

      const { usersWithNoBudget, usersWithBudget } = filterUsersNoBudget({
        allUsers: filteredNonAdminUsers,
        allocationYear,
        usersAndTheirBudget
      })

      const usersWithBudgetAndAttributes =
        usersWithBudget &&
        usersWithBudget.map((item) => {
          const selectedUser = allUsers.find((u) => u.Username.toUpperCase() === item.Username.toUpperCase())
          const selectedUserRole = selectedUser.Attributes?.find((attribute) => attribute.Name === 'custom:role')?.Value
          const attributes = selectedUser.Attributes
          item.Attributes = attributes
          item.accessLevel = roleLevels[selectedUserRole.toUpperCase()]
          return item
        })

      observer.receiveUsersDetails({
        usersWithNoBudget,
        usersAndTheirBudget: usersWithBudgetAndAttributes,
        allUsers: filteredNonAdminUsers
      })
    },
    failure: (e) => {
      observer.errorGettingData()
    }
  })
}

export default getUserElements
