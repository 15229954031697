const loginUserUseCase = ({ username, password }, { observer, authRepo }) => {
  authRepo.loginUser(
    { username, password },
    {
      success: (response) => {
        if (!response.loginUser) {
          observer.loginFailed()
          return
        }
        if (response.loginUser.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
          observer.loginChangePasswordChallenge(response.loginUser.Session)
          return
        }
        const {
          AccessToken: authToken,
          RefreshToken: refreshToken,
          ExpiresIn: expires
        } = response.loginUser.AuthenticationResult

        observer.loginSuccess({
          authToken,
          refreshToken,
          expires,
          role: response.loginUser.UserRole
        })
      },
      failure: () => {
        observer.loginFailed()
      }
    }
  )
}

export default loginUserUseCase
