import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ElementList from '../../AccountMapperNew/components/ElementList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const UserAllocationSelection = ({
  sorElements,
  updateAllocation,
  allocation,
  selectedElementLevel,
  removeGrants,
  addMoreGrants,
  index,
  selectedElementsLength
}) => {
  const element1List = sorElements?.elementList1
  const element2List = allocation.element1
    ? sorElements.elementList2.filter((el) => el.element1Id === allocation.element1.id)
    : []
  const element3List = allocation.element2
    ? sorElements.elementList3.filter((el) => el.element2Id === allocation.element2.id)
    : []
  const element4List = allocation.element3
    ? sorElements.elementList4.filter((el) => el.element3Id === allocation.element3.id)
    : []

  const onElementChange = (eventKey) => {
    const updatedAllocation = allocation
    const e = JSON.parse(eventKey)
    updatedAllocation[e.type] = e
    if (e.type === 'element1') {
      updatedAllocation.element2 && delete updatedAllocation.element2
      updatedAllocation.element3 && delete updatedAllocation.element3
      updatedAllocation.element4 && delete updatedAllocation.element4
    }
    if (e.type === 'element2') {
      updatedAllocation.element3 && delete updatedAllocation.element3
      updatedAllocation.element4 && delete updatedAllocation.element4
    }

    if (e.type === 'element3') {
      updatedAllocation.element4 && delete updatedAllocation.element4
    }
    updateAllocation(updatedAllocation)
  }

  return (
    <Row className="elements-list">
      <Col>
        <ElementList
          id={'element1ForIndex'}
          elementType="element1"
          elements={element1List}
          onChange={onElementChange}
          dropDownLabel={(allocation.element1 && allocation.element1.value) || 'please select'}
        />
      </Col>
      <Col>
        {selectedElementLevel > 1 && (
          <ElementList
            id={'element2ForIndex'}
            elementType="element2"
            elements={element2List}
            onChange={onElementChange}
            dropDownLabel={(allocation.element2 && allocation.element2.value) || 'please select'}
          />
        )}
      </Col>
      <Col>
        {selectedElementLevel > 2 && (
          <ElementList
            id={'element3ForIndex'}
            elementType="element3"
            elements={element3List}
            onChange={onElementChange}
            dropDownLabel={(allocation.element3 && allocation.element3.value) || 'please select'}
          />
        )}
      </Col>
      <Col>
        {selectedElementLevel > 3 && (
          <ElementList
            id={'element4ForIndex'}
            elementType="element4"
            elements={element4List}
            onChange={onElementChange}
            dropDownLabel={(allocation.element4 && allocation.element4.value) || 'please select'}
          />
        )}
      </Col>
      <Col md={1}>
        {allocation.index !== 0 && (
          <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" onClick={() => removeGrants(allocation)} />
        )}
        {index === selectedElementsLength - 1 && (
          <FontAwesomeIcon icon={faPlusCircle} className="add-more-icon" onClick={addMoreGrants} />
        )}
      </Col>
    </Row>
  )
}

UserAllocationSelection.propTypes = {}

export default UserAllocationSelection
