const reFetchAllAccounts = ({ observer, accountMapperRepo }) => {
  accountMapperRepo.reFetchAccounts({
    success: (accounts) => {
      observer.successfullyReFetchingAccounts()
    },
    failure: (e) => {
      observer.errorReFetchingAccounts()
    }
  })
}

export default reFetchAllAccounts
