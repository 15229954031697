const createOrUpdateSpace = (
  { year, token, element1, element2, element3, element4, estimates },
  { observer, spacesRepo }
) => {
  spacesRepo.createOrUpdateSpace(
    { year, token, element1, element2, element3, element4, estimates },
    {
      success: (spacesResponse) => {
        if (spacesResponse.error) {
          observer.errorUpdatingSpace()
          return
        }

        observer.receiveSpaceUpdates(spacesResponse.data)
      },
      failure: () => {
        observer.errorUpdatingSpace()
      }
    }
  )
}

export default createOrUpdateSpace
