import dollarPriceFormatter from '../../formatters/dollar_price_formatter'

const currentMonth = new Date().getMonth()

const getTotalByProperty = (budget, property = 'budgets', month = currentMonth) => {
  let total = 0

  if (budget.items) {
    budget.items.forEach((item) => {
      total += item[property][`_${month}`] || 0
    })
  } else {
    total += budget[property][`_${month}`] || 0
  }

  return total
}

const getVarianceToDate = (budget, isMonthly) => {
  let totalBudgets = 0
  let totalActuals = 0

  if (!isMonthly) {
    const { amount, usedYTD } = budget
    return dollarPriceFormatter(usedYTD - amount)
  }

  totalBudgets = getTotalByProperty(budget, 'budgets')
  totalActuals = getTotalByProperty(budget, 'actuals')

  return dollarPriceFormatter(totalActuals - totalBudgets)
}

export default getVarianceToDate
