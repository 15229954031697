import React from 'react'
import AllocationItem from './AllocationItem'

import { FormattedMessage } from 'react-intl'

const AllocationItems = (props) => {
  const { onChange, isNew, selectedAccountForRemap, sorElements, allocationYear } = props
  const [allocations, setAllocations] = React.useState([{ index: 0, percentage: 1 }])

  React.useEffect(() => {
    if (isNew) {
      setAllocations([{ index: 0, percentage: 1 }])
    }
  }, [isNew, setAllocations])

  React.useEffect(() => {
    if (selectedAccountForRemap && !isNew) {
      const selectedYearLatestMapping = selectedAccountForRemap.mappings.find(
        (item) => item.endDate === `12-31-${allocationYear}`
      )

      const currentAllocations = selectedYearLatestMapping.allocations.map((item, index) => {
        return {
          index,
          percentage: item.percentage,
          element1: {
            id: item.element1Id,
            value: sorElements.elementList1.find((el) => el.id === item.element1Id).value
          },
          element2: {
            id: item.element2Id,
            value: sorElements.elementList2.find((el) => el.id === item.element2Id).value
          },
          element3: {
            id: item.element3Id,
            value: sorElements.elementList3.find((el) => el.id === item.element3Id).value
          },
          element4: {
            id: item.element4Id,
            value: sorElements.elementList4.find((el) => el.id === item.element4Id).value
          }
        }
      })
      setAllocations(currentAllocations)
    }
  }, [selectedAccountForRemap, isNew, allocationYear, sorElements])

  const addNewAllocation = () => {
    const newArray = allocations.slice()
    const lastIndex = Math.max.apply(
      Math,
      newArray.map((o) => o.index)
    )
    newArray.push({
      index: lastIndex + 1,
      percentage: 1
    })
    setAllocations(newArray)
  }

  const removeAllocation = (selectedAllocation) => {
    const filtered = allocations.filter((el) => el.index !== selectedAllocation.index)
    const total = filtered.reduce((total, currentVal) => total + Number(currentVal.percentage || 0), 0)
    props.setTotalAllocation(total)
    setAllocations(filtered)
  }

  const updateAllocation = (selectedAllocation) => {
    const newArray = allocations.slice()
    const selectedAllocationIndex = newArray.findIndex((el) => el.index === selectedAllocation.index)
    newArray[selectedAllocationIndex] = selectedAllocation
    setAllocations(newArray)

    const total = newArray.reduce((total, currentVal) => total + Number(currentVal.percentage || 0), 0)
    props.setTotalAllocation(total)
  }

  React.useEffect(() => {
    onChange(allocations)
  }, [allocations, onChange])

  return (
    <table className="table account-allocation">
      <thead>
        <tr>
          <th>Type</th>
          {!isNew && <th>Date</th>}
          <th>
            <FormattedMessage id="sor.element1Name" defaultMessage="Business Unit" />
          </th>
          <th>
            <FormattedMessage id="sor.element2Name" defaultMessage="Department" />
          </th>
          <th>
            <FormattedMessage id="sor.element3Name" defaultMessage="Portfolio" />
          </th>
          <th>
            <FormattedMessage id="sor.element4Name" defaultMessage="Product" />
          </th>
          <th>Allocation</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {allocations.map((allocation, index) => {
          return (
            <AllocationItem
              key={allocation.index}
              allocation={allocation}
              removeAllocation={removeAllocation}
              updateAllocation={updateAllocation}
              addNewAllocation={addNewAllocation}
              index={index}
              allocationsLength={allocations.length}
              {...props}
            />
          )
        })}
      </tbody>
    </table>
  )
}

AllocationItems.propTypes = {}

export default AllocationItems
