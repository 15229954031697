import Roles from '../../../config/Roles'

const UserLevelMap = {
  [Roles.BU_MANAGERS]: 1,
  [Roles.DEPARTMENT_MANAGERS]: 2,
  [Roles.PORTFOLIO_MANAGERS]: 3,
  [Roles.PRODUCT_MANAGERS]: 4
}

const getUserLevel = (user) => {
  const levelOfAccess = user?.Attributes.find((attribute) => attribute.Name === 'custom:role')?.Value

  return UserLevelMap[levelOfAccess]
}

export default getUserLevel
