import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import ReactSelect from 'react-select'

import './InvoiceGeneration.scss'

const options = [
  { value: '1/1/23 - 1/31/23', label: '1/1/23 - 1/31/23' },
  { value: '2/1/23 - 2/28/23', label: '2/1/23 - 2/28/23' }
]

function InvoiceGeneration(props) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <div className="invoice-generation">
      <h1>Period</h1>
      <Row>
        <Col md={4}>
          <ReactSelect
            placeholder="Date Period"
            options={options}
            defaultValue={options[0]}
            onChange={(option) => {}}
            isClearable
          />
        </Col>
      </Row>

      <Card className="mt-4">
        <Card.Body>
          <Row>
            <Col md={6}>
              <div class="mb-3 row">
                <label for="vendor" class="col-sm-2 col-form-label">
                  Vendor
                </label>
                <div class="col-sm-10">
                  <input type="text" className="form-control" id="vendor" />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="po" class="col-sm-2 col-form-label">
                  PO
                </label>
                <div class="col-sm-10">
                  <input type="password" class="form-control" id="po" />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="invoice" class="col-sm-2 col-form-label">
                  Invoice #
                </label>
                <div class="col-sm-10">
                  <input type="password" class="form-control" id="invoice" />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div class="mb-3 row">
                <label for="internal" class="col-sm-2 col-form-label">
                  Internal
                </label>
                <div class="col-sm-10">
                  <input type="text" className="form-control" id="internal" />
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
          <table className="table ">
            <thead>
              <tr>
                <th>Business Unit</th>
                <th>Date Range</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Enterprise</td>
                <td>02/01/2023-02/28/2023</td>
                <td>$12.92</td>
              </tr>
              <tr>
                <td>Emerging Market</td>
                <td>02/01/2023-02/28/2023</td>
                <td>$141.17</td>
              </tr>
              <tr>
                <td>Automotive</td>
                <td>02/01/2023-02/28/2023</td>
                <td>$127.07</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-end">
                  Usage
                </td>
                <td>$281.16</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-end">
                  Credit
                </td>
                <td>$2000</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-end">
                  Tax
                </td>
                <td>$527.87</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-end">
                  Total
                </td>
                <td>$-1190.97</td>
              </tr>
            </tbody>
          </table>
          <div className="text-center">
            <button className="btn btn-primary">Create File</button>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default InvoiceGeneration
