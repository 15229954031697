import { BudgetStatus } from '../constants/BudgetStatus'
import Roles from '../../config/Roles'

const currentYear = new Date().getFullYear()

const isInputDisabled = (budget, selectedYear) => {
  const roles = JSON.parse(localStorage.getItem('roles'))

  if (!roles.includes(Roles.PRODUCT_MANAGERS)) {
    return true
  }

  return (
    selectedYear === currentYear &&
    [BudgetStatus.PENDING, BudgetStatus.APPROVED, BudgetStatus.REQUIRE_RESCHEDULE].includes(budget.status)
  )
}

export default isInputDisabled
