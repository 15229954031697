import { createColumnHelper } from '@tanstack/react-table'
import { getAccountStatus } from 'utils/reports-helper'

const columnHelper = createColumnHelper()

const getColumns = (firstRowData, inputMonth, inputYear) => {
  return [
    columnHelper.accessor('element1', {
      header: () => 'Business Unit'
    }),
    // Optionals

    ...(firstRowData.element2
      ? [
          columnHelper.accessor('element2', {
            header: () => 'Department'
          })
        ]
      : []),
    ...(firstRowData.element3
      ? [
          columnHelper.accessor('element3', {
            header: () => 'Portfolio'
          })
        ]
      : []),
    ...(firstRowData.element4
      ? [
          columnHelper.accessor('element4', {
            header: () => 'Product'
          })
        ]
      : []),
    columnHelper.accessor('accountId', {
      header: () => 'AWS Account'
    }),
    columnHelper.accessor('percentage', {
      header: () => 'Allocation'
    }),
    columnHelper.accessor(
      (row) => {
        return getAccountStatus(row.startDate, row.endDate, inputMonth, inputYear)
      },
      {
        id: 'status',
        header: 'Status',
        sortingFn: (rowA, rowB, columnId) => {
          const order = ['New', 'Expiring', 'Current', 'Future', 'Past']

          return order.indexOf(rowA.getValue(columnId)) - order.indexOf(rowB.getValue(columnId))
        },
        sortDescFirst: false
      }
    )
  ]
}

export default getColumns
