import React from 'react'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import getPeriodCardsForUser from '../use-cases/get-period-cards-for-user'
import WeeklyAllocationReport from './weekly-allocation-report/WeeklyAllocationReport'

const PeriodCards = () => {
  const { repoFactory, selectedYear } = React.useContext(AppContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [actualsData, setActualsData] = React.useState(null)

  React.useEffect(() => {
    setIsLoading(true)
    getPeriodCardsForUser(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        observer: {
          receivePeriodCards: (response) => {
            setIsLoading(false)
            setActualsData(response)
          },
          errorReceivingPeriodCards: () => {
            setIsLoading(false)
            setActualsData(null)
          }
        },
        timePeriodsRepo: repoFactory.timePeriodsRepo()
      }
    )
  }, [selectedYear, repoFactory, setActualsData, setIsLoading])

  return (
    <div className="actuals-data">
      {isLoading && <LoadingSpinner />}
      {actualsData && <WeeklyAllocationReport actualsData={actualsData} />}
    </div>
  )
}

export default PeriodCards
