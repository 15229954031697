import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { percentFormatter } from 'formatters/percent_formatter'
import Roles from 'config/Roles'

const columnHelper = createColumnHelper()

const getColumns = () => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const columns = []

  if (roles.includes(Roles.FINANCIAL_ADMINS)) {
    columns.push(
      columnHelper.accessor('element1', {
        header: () => 'Business Unit'
      })
    )
  } else if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
    columns.push(
      columnHelper.accessor('element3', {
        header: () => 'Portfolio'
      })
    )
  } else {
    columns.push(
      columnHelper.accessor('element4', {
        header: () => 'Product'
      })
    )
  }

  return columns.concat([
    columnHelper.accessor('element2', {
      header: () => 'Current Period',
      cell: (info) => {
        return ''
      }
    }),
    columnHelper.accessor('element2', {
      header: () => 'Available Credit',
      cell: (info) => {
        return ''
      }
    }),
    columnHelper.accessor('monthToDateUsage', {
      header: () => 'Current Month',
      cell: (info) => {
        return dollarPriceFormatter(info.getValue())
      }
    }),
    columnHelper.accessor('mom', {
      header: () => 'Trend',
      cell: (info) => {
        const value = info.getValue()
        return <div className={value === null ? '' : value < 0 ? 'green' : 'red'}>{percentFormatter(value)}</div>
      }
    }),
    columnHelper.accessor('annual', {
      header: () => 'Annual',
      cell: (info) => dollarPriceFormatter(info.getValue())
    })
  ])
}

export default getColumns
