export const BudgetStatus = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
  NOT_INITIATED: 'notInitiated',
  PENDING_UPDATE: 'pendingUpdate',
  REQUIRE_RESCHEDULE: 'requireReschedule',
  RESCHEDULE_REJECTED: 'rescheduleRejected'
}

export const BudgetStatusLabel = {
  approved: 'Approved',
  rejected: 'Rejected',
  pending: 'Pending',
  notInitiated: 'Open',
  pendingUpdate: 'Pending Update',
  requireReschedule: 'Require Reschedule',
  rescheduleRejected: 'Reschedule rejected'
}
