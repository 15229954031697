import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import axios from 'axios'
import HttpClient from './http_client'
import RepoFactory from './repo_factory'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import English from './locale/locale-en.json'

const _axios = {
  all: axios.all,
  request: axios.request,
  retry: axios,
  interceptors: axios.interceptors
}

const httpClientConfig = {
  timeout: 10000
}

const httpClient = new HttpClient({
  axiosClient: _axios,
  config: httpClientConfig
})

const repoFactory = new RepoFactory({
  httpClient,
  httpClientConfig,
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
  GRAPHQL_API_KEY: process.env.REACT_APP_GRAPHQL_API_KEY
})

const locale = navigator.language
let lang
if (locale === 'en') {
  lang = English
} else {
  lang = English
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <IntlProvider locale={locale} messages={lang}>
        <App repoFactory={repoFactory} location={window.location} />
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('cloudscal3-web-root')
)

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
