const changePasswordChallengeUseCase = ({ username, password, session }, { observer, authRepo }) => {
  authRepo.changePasswordChallenge(
    { username, password, session },
    {
      success: (response) => {
        if (!response.completePasswordChallenge) {
          observer.changePasswordFailed()
          return
        }
        const {
          AccessToken: authToken,
          RefreshToken: refreshToken,
          ExpiresIn: expires
        } = response.completePasswordChallenge.AuthenticationResult

        observer.changePasswordSuccess({
          authToken,
          refreshToken,
          expires,
          role: response.completePasswordChallenge.UserRole
        })
      },
      failure: () => {
        observer.changePasswordFailed()
      }
    }
  )
}

export default changePasswordChallengeUseCase
