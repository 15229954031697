import React, { useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import getVarianceReportColumns from './VarianceReportColumns'

import { isCurrentMonth, isFutureMonth } from 'utils/reports-helper'
import getIsValidBudgetData from 'Budgets/helpers/validBudgetData'
import { isEmpty } from 'utils'

import './VarianceReport.scss'

const VarianceReport = ({ budgetsData, year, isGrouped }) => {
  const [budgets, setBudgets] = useState([])
  const [selectedYear, setSelectedYear] = useState(year)
  const [grouping] = useState(['businessUnit', 'deparment', 'portafolio'])
  const [columnPinning] = useState({
    left: ['businessUnit', 'deparment', 'portafolio', 'product']
  })
  const [infoMessage, setInfoMessage] = useState('')

  const table = useReactTable({
    data: budgets,
    columns: getVarianceReportColumns(selectedYear, budgets),
    state: {
      grouping,
      columnPinning
    },
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    if (getIsValidBudgetData(budgetsData, isGrouped)) {
      let inputData = budgetsData

      if (isGrouped) {
        inputData = []

        budgetsData.forEach((data) => {
          data.items.forEach((item) => {
            inputData.push(item)
          })
        })
      }

      const items = inputData.map((budget) => {
        const { actuals, budgets } = budget
        const months = {}

        if (isEmpty(actuals)) {
          for (let i = 0; i < 12; i++) {
            actuals[`_${i}`] = 0
          }
        }

        let y2d = 0
        for (const [key, value] of Object.entries(actuals)) {
          const budget = budgets[key] || 0
          const actual = value
          const variance = budget - actual
          y2d += variance
          months[key] = {
            actual,
            budget,
            variance,
            y2d
          }
        }
        return {
          businessUnit: budget.element1,
          deparment: budget.element2,
          portafolio: budget.element3,
          product: budget.element4,
          months
        }
      })
      setBudgets([...items])
      setInfoMessage('')
    } else {
      setBudgets([])
      setInfoMessage('Content - Report failed to load. Please validate Budget Planning data')
    }

    setSelectedYear(year)
  }, [budgetsData, year, isGrouped])

  return (
    <>
      {infoMessage ? (
        <div className="alert alert-info mt-3">{infoMessage}</div>
      ) : (
        <div className="table-responsive d-flex">
          <table className="table table-fixed table-bordered table-hover table-striped">
            <thead>
              {table.getLeftHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan} className="text-nowrap">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getLeftVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {cell.getIsGrouped() ? (
                        <>
                          <button
                            {...{
                              onClick: row.getToggleExpandedHandler(),
                              style: {
                                cursor: row.getCanExpand() ? 'pointer' : 'normal'
                              }
                            }}
                          >
                            {row.getIsExpanded() ? (
                              <FontAwesomeIcon icon={faChevronDown} />
                            ) : (
                              <FontAwesomeIcon icon={faChevronRight} />
                            )}{' '}
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                            <span className="group-quantity">({row.subRows.length})</span>
                          </button>

                          <div className="variance-row-content-template">
                            <h6>
                              Variance <span className="y2d">(Y2D)</span>
                            </h6>
                            <div className="text-end">
                              <h6>Actual</h6>
                              <h6>Budget</h6>
                            </div>
                          </div>
                        </>
                      ) : cell.getIsAggregated() ? (
                        flexRender(
                          cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : cell.getIsPlaceholder() ? null : (
                        <>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          {
                            <div className="variance-row-content-template">
                              <h6>
                                Variance <span className="y2d">(Y2D)</span>
                              </h6>
                              <div className="text-end">
                                <h6>Actual</h6>
                                <h6>Budget</h6>
                              </div>
                            </div>
                          }
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <table className="table table-bordered  table-hover table-striped">
            <thead>
              {table.getCenterHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={
                        isCurrentMonth(selectedYear, header.id)
                          ? 'current-month'
                          : isFutureMonth(selectedYear, header.id)
                          ? 'future-month'
                          : ''
                      }
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getCenterVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={
                        isCurrentMonth(selectedYear, cell.column.id)
                          ? 'current-month'
                          : isFutureMonth(selectedYear, cell.column.id)
                          ? 'future-month'
                          : ''
                      }
                    >
                      {cell.getIsAggregated()
                        ? flexRender(
                            cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        : cell.getIsPlaceholder()
                        ? null
                        : flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default VarianceReport
