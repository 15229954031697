import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import moment from 'moment'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()

const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const getMonthIndex = (shortMonthName) => {
  return shortMonthNames.indexOf(shortMonthName)
}

export const getFormattedRow = (value, year, month) => {
  return isFutureMonth(year, month) ? '' : dollarPriceFormatter(value)
}

export const isCurrentMonth = (year, month) => {
  let monthIndex = month

  if (isNaN(monthIndex)) {
    monthIndex = getMonthIndex(month)
  }

  if (monthIndex < 0) return false

  if (currentYear === year && currentMonth === monthIndex) {
    return true
  }

  return false
}

export const isFutureMonth = (year, month) => {
  let monthIndex = month

  if (isNaN(monthIndex)) {
    monthIndex = getMonthIndex(month)
  }

  if (monthIndex < 0) return false

  if (year > currentYear) {
    return true
  } else if (currentYear === year && monthIndex > currentMonth) {
    return true
  }

  return false
}

export const getAccountStatus = (startDate, endDate, inputMonth, inputYear) => {
  const end = moment(endDate, 'MM-DD-YYYY')
  const start = moment(startDate, 'MM-DD-YYYY')
  const input = moment(`${inputMonth}-${inputYear}`, 'MM-YYYY')

  if (input.year() > end.year()) {
    return 'Past'
  } else {
    if (`${input.month()}-${input.year()}` === `${end.month()}-${end.year()}`) {
      return 'Expiring'
    }

    if (`${input.month()}-${input.year()}` === `${start.month()}-${start.year()}`) {
      return 'New'
    }

    if (start.isAfter(input)) {
      return 'Future'
    }

    if (end.isBefore(input)) {
      return 'Past'
    }

    if (input.month() !== start.month() && input.month() !== end.month()) {
      return 'Current'
    }
  }
}
