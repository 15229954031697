import React, { memo } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import getPublicRoutes from '../routing/get_public_routes'
import getPrivateRoutes from '../routing/get_private_routes'
import Routes from '../routes'
import MenuBar from './MenuBar/MenuBar'
import { Col, Row } from 'react-bootstrap'
import './main.scss'
import private_routes from '../config/private_routes'

function MainContent(props) {
  return (
    <main className="main">
      <Switch>
        {getPublicRoutes(props)}
        {props.isAuthenticated ? (
          <Row>
            <Col xs={12} className="phn">
              <MenuBar {...props} />
            </Col>
            <Col xs={12} className="tab-content">
              {getPrivateRoutes(private_routes, props)}
            </Col>
          </Row>
        ) : (
          <Redirect to={Routes.LOGIN.PATH} />
        )}
      </Switch>
    </main>
  )
}

export default memo(MainContent)
