import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import getUsageStats from '../use-cases/get-usage-stats'
import getAccountsMap from '../use-cases/get-accounts-map'
import { Col, Row } from 'react-bootstrap'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import MonthlyUsage from './MonthlyUsage'
import './summary.scss'
import AllowedAllocations from './AllowedAllocations'
import Billing from './Billing'
import CloudSpendCards from './cloudSpendCards/CloudSpendCards'
import { TimeCardStatus } from '../constants/TimeCardStatus'
import getBudgetsMap from 'Budgets/use-cases/mapAllBudgets'
import getActualsForUserNew from 'Actuals/use-cases/get-actuals-for-user-new'
import { ToggleOptions } from '../constants/ToogleOptions'
import UnAllocatedAccounts from './UnAllocatedAccounts'
import Roles from '../../config/Roles'

const Summary = () => {
  const { repoFactory } = useContext(AppContext)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [selectedToggle, setSelectedToggle] = useState(ToggleOptions[1].value)
  const [isLoading, setIsLoading] = useState(false)
  const [usageStats, setUsageStats] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [timeCards, setTimeCards] = useState(null)
  const [unAllocatedAccounts, setUnAllocatedAccounts] = useState(null)
  const [budgetsData, setBudgetsData] = React.useState(null)
  const [actualsData, setActualsData] = React.useState(null)
  const [userRole] = React.useState(JSON.parse(localStorage.getItem('roles')))

  useEffect(() => {
    setIsLoading(true)
    getBudgetsMap(
      {
        year,
        token: localStorage.getItem('authToken'),
        userRole
      },
      {
        budgetsRepo: repoFactory.budgetsRepo(),
        observer: {
          errorReceivingBudgets: () => {
            setIsLoading(false)
            setBudgetsData([])
          },
          receiveBudgets: (budgets) => {
            setIsLoading(false)
            setBudgetsData(budgets)
          }
        }
      }
    )
  }, [repoFactory, setIsLoading])

  useEffect(() => {
    getAccountsMap({
      accountMapperRepo: repoFactory.accountMapperRepoNew(),
      observer: {
        errorReceivingAccounts: () => {},
        receiveAccounts: (response) => {
          setAccounts(response)
        }
      }
    })
  }, [repoFactory, setAccounts])

  useEffect(() => {
    getAllUsageStats()
  }, [repoFactory, month])

  const getAllUsageStats = () => {
    setIsLoading(true)
    getUsageStats(
      {
        year,
        month,
        token: localStorage.getItem('authToken')
      },
      {
        summaryRepo: repoFactory.summaryRepo(),
        observer: {
          receiveUsages: (response) => {
            setIsLoading(false)
            setUsageStats(response)
          },
          errorReceivingUsages: () => {
            setIsLoading(false)
            setUsageStats(null)
          },
          receiveTimeCards: (response) => {
            setTimeCards(response)
          },
          errorReceivingTimeCards: () => {
            setTimeCards(null)
          },
          receiveUnallocatedAccounts: (response) => {
            setUnAllocatedAccounts(response)
          },
          errorReceivingUnallocatedAccounts: () => {
            setUnAllocatedAccounts(null)
          }
        }
      }
    )
  }

  React.useEffect(() => {
    setIsLoading(true)
    getActualsForUserNew(
      {
        year,
        token: localStorage.getItem('authToken')
      },
      {
        actualsRepo: repoFactory.actualsRepo(),
        observer: {
          receiveActuals: ({ actuals }) => {
            setActualsData(actuals)
            setIsLoading(false)
          },
          errorReceivingActuals: () => {
            setIsLoading(false)
            setActualsData(null)
          }
        }
      }
    )
  }, [repoFactory, setActualsData, setIsLoading, year])

  const updateToggle = (item) => {
    setSelectedToggle(item)
    if (item === 'previous') {
      if (new Date().getMonth() === 0) {
        setMonth(12)
        setYear(new Date().getFullYear() - 1)
      } else {
        setMonth(new Date().getMonth())
        setYear(new Date().getFullYear())
      }
    } else {
      setMonth(new Date().getMonth() + 1)
      setYear(new Date().getFullYear())
    }
  }

  const keys = ['element1', 'element2', 'element3', 'element4', 'year', 'month', 'week', 'total']

  const filtered =
    timeCards &&
    timeCards.filter(
      (
        (s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|'))
      )(new Set())
    )

  const timeCardTotals = {
    approved: 0,
    open: 0,
    remainingOpen: usageStats?.monthToDateUsage,
    unallocated: usageStats?.monthToDateUsage
  }

  filtered &&
    filtered.forEach((item) => {
      let key
      if (item.status === TimeCardStatus['APPROVE']) {
        key = 'approved'
        timeCardTotals.remainingOpen -= item.total
      } else {
        key = 'open'
      }
      timeCardTotals.unallocated -= item.total
      timeCardTotals[key] += item.total
    })

  return (
    <div className="summary mb-5">
      {isLoading && <LoadingSpinner />}
      {usageStats && (
        <>
          <Row>
            <Col xs={4}>
              <MonthlyUsage
                usageStats={usageStats}
                selectedToggle={selectedToggle}
                updateToggle={updateToggle}
                timeCardTotals={timeCardTotals}
                showApprovedAndOpenSpend={!!timeCards}
              />
              {unAllocatedAccounts && userRole && Roles.FINANCIAL_ADMINS === userRole[0] && (
                <>
                  <h2>Unallocated Spend</h2>
                  <UnAllocatedAccounts unAllocatedAccounts={unAllocatedAccounts} accounts={accounts} />
                </>
              )}
            </Col>
            <Col xs={8}>
              <h2>Financial Scope</h2>
              <AllowedAllocations accounts={accounts} actualsData={actualsData} inputMonth={month} inputYear={year} />
            </Col>
          </Row>
          <Row className="top-billing">
            <h2>Financial Snapshot</h2>
            <Billing usageStats={usageStats} />
          </Row>
          <CloudSpendCards
            timeCardTotals={timeCardTotals}
            timeCards={filtered}
            inputMonth={month}
            getUsageStats={getAllUsageStats}
            budgetsData={budgetsData}
            selectedToggle={selectedToggle}
          />
        </>
      )}
    </div>
  )
}

export default Summary
