import React from 'react'
import getActualsForUserNew from 'Actuals/use-cases/get-actuals-for-user-new'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

import UsageTree from '../../../Actuals/components/usage-tree/UsageTree'
import getAccountsMap from 'Summary/use-cases/get-accounts-map'

const MonthlyAllocation = () => {
  const { repoFactory, selectedYear } = React.useContext(AppContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [actualsData, setActualsData] = React.useState(null)
  const [accounts, setAccounts] = React.useState(null)

  React.useEffect(() => {
    getAccountsMap({
      accountMapperRepo: repoFactory.accountMapperRepoNew(),
      observer: {
        errorReceivingAccounts: () => {},
        receiveAccounts: (response) => {
          setAccounts(response)
        }
      }
    })
  }, [repoFactory, setAccounts])

  React.useEffect(() => {
    setIsLoading(true)
    getActualsForUserNew(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        actualsRepo: repoFactory.actualsRepo(),
        observer: {
          receiveActuals: ({ actuals }) => {
            setActualsData(actuals)
            setIsLoading(false)
          },
          errorReceivingActuals: () => {
            setIsLoading(false)
            setActualsData(null)
          }
        }
      }
    )
  }, [selectedYear, repoFactory, setActualsData, setIsLoading])

  return (
    <div className="actuals-data">
      {isLoading && <LoadingSpinner />}
      {actualsData && <UsageTree actualsData={actualsData} year={selectedYear} accountsData={accounts} />}
    </div>
  )
}

export default MonthlyAllocation
