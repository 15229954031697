import { Badge, Card, Col, Form, Row } from 'react-bootstrap'
import React from 'react'
import moment from 'moment'

import dollarPriceFormatter from 'formatters/dollar_price_formatter'

const BudgetInputField = ({ month, selectedYear, onChange, isInputDisabled, data, estimatedBudgetsData }) => {
  const inputRef = React.useRef(null)

  return (
    <Col key={month.value}>
      <Form.Group className="my-2">
        <Card className="budget-input-card">
          <Card.Body>
            <Card.Title className="title">
              <Row>
                <Col xs={8}>
                  {month.name} {selectedYear}
                </Col>
                <Col xs={4}>
                  <Badge className="calender-days">
                    CD
                    <span>
                      {moment(`${selectedYear}-${Number(month.value.substring(1)) + 1}`, 'YYYY-MM').daysInMonth()}
                    </span>
                  </Badge>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col xs={8}>
                <Form.Control
                  className="input-box"
                  ref={inputRef}
                  onChange={onChange}
                  onFocus={() => {
                    inputRef.current.value = data[month.value]
                    inputRef.current.type = 'number'
                  }}
                  onBlur={() => {
                    inputRef.current.type = 'text'
                    inputRef.current.value = dollarPriceFormatter(data[month.value])
                  }}
                  id={month.value}
                  name={month.value}
                  defaultValue={dollarPriceFormatter(data[month.value])}
                  disabled={isInputDisabled}
                />
              </Col>
              <Col xs={4}>
                <Badge className="calender-days">{dollarPriceFormatter(estimatedBudgetsData[month.value])}</Badge>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form.Group>
    </Col>
  )
}

export default BudgetInputField
