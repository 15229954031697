import React, { useEffect } from 'react'
import getAllBudgets from 'Budgets/use-cases/getAllBudgets'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

import VarianceReport from '../../../Actuals/components/variance-report/VarianceReport'

const Variance = (props) => {
  const { repoFactory, selectedYear } = React.useContext(AppContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [budgetsData, setBudgetsData] = React.useState(null)
  const [isGroupedData, setIsGroupedData] = React.useState(null)

  useEffect(() => {
    setIsLoading(true)
    getAllBudgets(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken'),
        userRole: JSON.parse(localStorage.getItem('roles'))
      },
      {
        budgetsRepo: repoFactory.budgetsRepo(),
        observer: {
          errorReceivingBudgets: () => {
            setIsLoading(false)
            setBudgetsData([])
            setIsGroupedData(false)
          },
          receiveBudgets: ({ budgets, isGrouped }) => {
            setIsLoading(false)
            setBudgetsData(budgets)
            setIsGroupedData(isGrouped)
          }
        }
      }
    )
  }, [selectedYear, repoFactory, setIsLoading, setIsGroupedData, setBudgetsData])

  return (
    <div className="actuals-data">
      {isLoading && <LoadingSpinner />}
      {budgetsData && <VarianceReport budgetsData={budgetsData} year={selectedYear} isGrouped={isGroupedData} />}
    </div>
  )
}

export default Variance
