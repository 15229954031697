import moment from 'moment/moment'

export const getDateRange = (weekNumber, inputMonth, inputYear = new Date().getFullYear()) => {
  if (weekNumber === undefined || weekNumber === null) {
    return '--'
  }

  const lastWeekNumberOfYear = moment(`12-31-${inputYear}`, 'MM-DD-YYYY').isoWeek()

  if (weekNumber > lastWeekNumberOfYear) {
    return '--'
  }

  let startDateObj = moment(`01-01-${inputYear}`).day('Sunday').week(weekNumber)
  let endDateObj = moment(`01-01-${inputYear}`).day('Saturday').week(weekNumber)

  if (startDateObj.clone().month() + 1 < inputMonth) {
    startDateObj = moment(`${inputMonth}-01-${startDateObj.clone().year()}`)
  }

  if (endDateObj.clone().month() + 1 > inputMonth) {
    endDateObj = startDateObj.clone().endOf('month')
  }

  return `${startDateObj.clone().format('MM/DD/YY')} - ${endDateObj.clone().format('MM/DD/YY')}`
}

export const getNumberofDays = (weekNumber, inputYear = new Date().getFullYear()) => {
  if (weekNumber === undefined || weekNumber === null) {
    return '--'
  }

  const lastWeekNumberOfYear = moment(`12-31-${inputYear}`, 'MMDDYYYY').week()

  if (weekNumber > lastWeekNumberOfYear) {
    return '--'
  }

  let startDateObj = moment().day('Sunday').week(weekNumber)
  let endDateObj = moment().day('Saturday').week(weekNumber)

  return endDateObj.diff(startDateObj, 'days') + 1
}
