import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  useSortBy
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import dollarPriceFormatter from '../../formatters/dollar_price_formatter'
import moment from 'moment'

const columnHelper = createColumnHelper()

const UnAllocatedAccounts = ({ unAllocatedAccounts, accounts }) => {
  const [data, setData] = useState([])
  const [columns] = useState([
    columnHelper.accessor('accountId', {
      header: () => 'Account ID'
    }),
    columnHelper.accessor('createdDate', {
      header: () => 'Creation Date',
      cell: (info) => {
        return moment.utc(info.getValue()).local().format('MM/DD/YY')
      }
    }),
    columnHelper.accessor('spending', {
      header: () => 'Spending',
      cell: (info) => {
        return dollarPriceFormatter(info.getValue())
      }
    })
  ])
  const [sorting, setSorting] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false
        }
      ]
    },
    useSortBy
  })

  useEffect(() => {
    setData([...unAllocatedAccounts])
  }, [unAllocatedAccounts])

  const getAccountName = (accountId) => accounts[accountId]?.accountName

  return (
    <div className="table-responsive" style={{ maxHeight: '110px' }}>
      <table className="table table-hover table-striped">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === 'accountId' ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {getAccountName(cell.getValue())}
                          </Tooltip>
                        )}
                      >
                        <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default UnAllocatedAccounts
