export const ToggleOptions = [
  {
    key: 'Previous',
    value: 'previous',
    label: 'Previous'
  },
  {
    key: 'Current',
    value: 'current',
    label: 'Current'
  }
]
