function CommonRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getSorElements = (selectedYear, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query GetSorElements($year: Int!) {
                      getSorListByYear(year: $year) {
                        data {
                          element1List {
                            id
                            value
                          }
                          element2List {
                            id
                            value
                            element1Id
                          }
                          element3List {
                            id
                            value
                            element2Id
                          }
                          element4List {
                            id
                            value
                            element3Id
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                      sorMapping(year: $year) {
                        element1Name
                        element2Name
                        element3Name
                        element4Name
                        id
                      }
                      getLastRunCur {
                        lastRunTime
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`,
          variables: {
            year: selectedYear
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default CommonRepo
