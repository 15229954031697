const listSpaces = ({ year, token }, { observer, spacesRepo }) => {
  spacesRepo.listSpaces(
    { year, token },
    {
      success: (spacesResponse) => {
        if (spacesResponse.error) {
          observer.errorReceivingSpaces()
          return
        }

        observer.receiveSpaces(spacesResponse.data)
      },
      failure: () => {
        observer.errorReceivingSpaces()
      }
    }
  )
}

export default listSpaces
