import { Card, Col, Row } from 'react-bootstrap'
import { Status, TimeCardStatus } from '../../constants/TimeCardStatus'
import CardDetails from './CardDetails'
import React from 'react'
import moment from 'moment'
import { ToggleOptions } from '../../constants/ToogleOptions'

const TimeCards = ({ timeCardTotals, timeCards, inputMonth, selectedToggle }) => {
  const mappedResponse = {}
  timeCards &&
    timeCards.forEach((item) => {
      let key = item.status
      if (item.status === TimeCardStatus['RESOLVE']) {
        key = Status['Open']
      }

      if (mappedResponse[key]) {
        mappedResponse[key].push(item)
      } else {
        mappedResponse[key] = [item]
      }
    })

  // if (ToggleOptions[1].value === selectedToggle) {
  //   const obj = {
  //     element4: 'unallocated',
  //     week: moment().week(),
  //     status: 'Current',
  //     total: timeCardTotals?.unallocated
  //   }
  //
  //   if (mappedResponse['Open']) {
  //     mappedResponse['Open'].unshift(obj)
  //   } else {
  //     mappedResponse['Open'] = [obj]
  //   }
  // }

  return (
    <div className="time-cards pb-5">
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Body>
              {mappedResponse['Open'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedResponse['Open']} type="Open" />
              ) : (
                <Card.Title>No Open Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              {mappedResponse['Accept'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedResponse['Accept']} type="Accept" />
              ) : (
                <Card.Title>No Accepted Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              {mappedResponse['Dispute'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedResponse['Dispute']} type="Dispute" />
              ) : (
                <Card.Title>No Disputed Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              {mappedResponse['Approve'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedResponse['Approve']} type="Approve" />
              ) : (
                <Card.Title>No Approved Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TimeCards
