function SummaryRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getUsageStats = ({ year, token, month }, { success, failure }) => {
    const query = `mutation ($token: String!, $year: Int!, $month: Int) {
                      getUsageStats(actualsSearch: {token: $token, year: $year, month: $month}) {
                        data {
                          monthToDateUsage
                          currentMonthAllocations {
                            element1
                            element2
                            element3
                            element4
                            startDate
                            endDate
                            accountId
                            percentage
                          }
                          billings {
                            element1
                            element2
                            element3
                            element4
                            annual
                            monthToDateUsage
                            mom
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                      getTimeCardsForUser(actualsSearch: {token: $token, year: $year, month: $month}) {
                        data {
                          id
                          status
                          week
                          year
                          month
                          total
                          element1
                          element2
                          element3
                          element4
                          isCurrent
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                      getUnallocatedAccounts(actualsSearch: {token: $token, year: $year, month: $month}) {
                        data {
                          accountId
                          accountName
                          createdDate
                          spending
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            month
          }
        }
      },
      {
        success: (response) => {
          const { getUsageStats, getTimeCardsForUser, getUnallocatedAccounts } = response.data.data
          success({ getUsageStats, getTimeCardsForUser, getUnallocatedAccounts })
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.updateTimeCardStatus = ({ token, id, status }, { success, failure }) => {
    const query = `mutation ($token: String!, $id: ID!, $status: String!) {
                      updateTimeCardStatus(timeCardStatus: {token: $token, id: $id, status: $status}) {
                        data 
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            id,
            status
          }
        }
      },
      {
        success: (response) => {
          const { updateTimeCardStatus } = response.data.data
          success(updateTimeCardStatus)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default SummaryRepo
