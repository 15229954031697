import React, { useEffect, useState } from 'react'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import getColumns from './BillingColumns'

const Billing = ({ usageStats: { billings } }) => {
  const [data, setData] = useState([...billings])
  const [columns, setColumns] = useState(getColumns(billings[0]))
  const [sorting, setSorting] = useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  useEffect(() => {
    setData([...billings])
    setColumns(getColumns(billings[0]))
  }, [billings])

  return (
    <div>
      <table className="table table-hover table-striped">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Billing
