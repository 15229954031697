const inviteUserUseCase = ({ username, email, role }, { observer, userRepo }) => {
  userRepo.inviteUser(
    { username, email, role },
    {
      success: (data) => {
        if (!data.createUser) {
          observer.errorWhileInvitingUser()
          return
        }
        observer.successfulInviteOfUser()
      },
      failure: (e) => {
        observer.errorWhileInvitingUser()
      }
    }
  )
}

export default inviteUserUseCase
