import React, { useEffect, useState } from 'react'
import BudgetGauge from './BudgetGauge'
import { Card } from 'react-bootstrap'
import Slider from 'react-slick'

const BudgetsList = ({ budgets, setSelectedBudget, isGrouped, isMonthly }) => {
  const [selectedBudgetIndex, setSelectedBudgetIndex] = useState()
  const slidesToShow = 4

  useEffect(() => {
    setSelectedBudgetIndex(null)
  }, [setSelectedBudgetIndex, budgets])

  const settings = {
    ...(budgets.length < slidesToShow && { className: 'slick-align-left' }),
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1
  }

  const budgetsMap = budgets.map((item, index) => {
    return (
      <BudgetGauge
        key={item.element4}
        budget={item}
        isGrouped={isGrouped}
        isMonthly={isMonthly}
        selectedBudget={(budget) => {
          setSelectedBudget(budget)
          setSelectedBudgetIndex(index)
        }}
        isSelected={index === selectedBudgetIndex}
      />
    )
  })

  return (
    <>
      {budgets.length > 0 && (
        <Card className="section-gray mb-3">
          <Card.Body>
            <div className="budget-list">
              <Slider {...settings}>{budgetsMap}</Slider>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default BudgetsList
