import React from 'react'
import SemiCircularProgressbar from 'components/common/SemiCircularProgressbar/SemiCircularProgressbar'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { Card, Col, Row } from 'react-bootstrap'

import { BudgetStatus } from 'Budgets/constants/BudgetStatus'
import './GaugeSummary.scss'

const currentMonth = new Date().getMonth()

function getCurrentQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1)
}

const getTotalByProperty = (budgets, property = 'budgets', month = currentMonth) => {
  let total = 0
  budgets.forEach((budget) => {
    if (budget.status === BudgetStatus.APPROVED) {
      if (budget.items) {
        budget.items.forEach((item) => {
          total += item[property][`_${month}`] || 0
        })
      } else {
        total += budget[property][`_${month}`] || 0
      }
    }
  })
  return total
}

const getTotals = (budgets, isMonthly, month = currentMonth) => {
  let totalBudgets = 0
  let totalActuals = 0
  let variance = 0
  let variancePercentage = 0

  if (!isMonthly) {
    budgets.forEach((item) => {
      totalBudgets += item.amount
      totalActuals += item.usedYTD
    })
  } else {
    totalBudgets = getTotalByProperty(budgets, 'budgets', month)
    totalActuals = getTotalByProperty(budgets, 'actuals', month)
  }

  variance = totalActuals - totalBudgets
  variancePercentage =
    Math.floor((Math.abs(totalActuals - totalBudgets) / ((totalActuals + totalBudgets) / 2)) * 100) || 0

  return {
    totalBudgets,
    totalActuals,
    percentage: Math.floor((totalActuals / totalBudgets) * 100) || 0,
    variance,
    variancePercentage
  }
}

const getSpendQuarter = (budgets) => {
  const quarter = getCurrentQuarter()
  const startMonth = (quarter - 1) * 3
  const endMonth = startMonth + 2
  let totalSpendQuarter = 0
  for (let i = startMonth; i <= endMonth; i++) {
    totalSpendQuarter += getTotalByProperty(budgets, 'actuals', i)
  }
  return totalSpendQuarter
}

const getColorCardClassName = (percent) => {
  if (percent < 25) {
    return 'card-green'
  } else if (percent < 60) {
    return 'card-yellow'
  } else if (percent < 90) {
    return 'card-orange'
  }

  return 'card-red'
}

const getEOYPlanned = (budgets) => {
  const { totalActuals, totalBudgets } = getTotals(budgets, true)

  return totalBudgets - totalActuals
}

const getUnallocatedBudget = (budgets) => {
  const annualBudget = budgets.reduce((a, c) => a + c.amount, 0)
  let plannedBudget = 0
  for (let i = 0; i < 12; i++) {
    plannedBudget += getTotalByProperty(budgets, 'budgets', i)
  }
  return annualBudget - plannedBudget
}

const GaugeSummary = ({ budgets, isMonthly }) => {
  const { percentage, totalBudgets, totalActuals, variance, variancePercentage } = getTotals(budgets, isMonthly)
  return (
    <Row>
      <Col>
        <Card className="gauge-summary-card card-with-shadow mb-3">
          <Card.Body className="d-flex">
            <div className="card-progressbar">
              <Card.Title className="mb-5">Spend</Card.Title>

              <SemiCircularProgressbar percent={percentage} radio="120" />
            </div>

            <Card className={`card-color ${getColorCardClassName(percentage)}`}>
              <Card.Body>
                <Card.Subtitle>Spend</Card.Subtitle>
                <h2 className="mb-3">{dollarPriceFormatter(totalActuals)}</h2>
                <Card.Subtitle>Planned</Card.Subtitle>
                <h3 className="mb-3">{dollarPriceFormatter(totalBudgets)}</h3>
                {isMonthly ? (
                  <>
                    <Card.Subtitle>Last Month Spend</Card.Subtitle>
                    <h3>{dollarPriceFormatter(getTotalByProperty(budgets, 'actuals', currentMonth - 1))}</h3>
                  </>
                ) : (
                  <>
                    <Card.Subtitle>This Quarter Spend</Card.Subtitle>
                    <h3>{dollarPriceFormatter(getSpendQuarter(budgets))}</h3>
                  </>
                )}
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="gauge-summary-card card-with-shadow">
          <Card.Body className="d-flex justify-content-between">
            <div className=" card-progressbar">
              <Card.Title className="mb-5">Variance In Spend</Card.Title>

              <SemiCircularProgressbar percent={variancePercentage} radio="120" />
            </div>

            <Card className={`card-color ${getColorCardClassName(variancePercentage)}`}>
              <Card.Body>
                <Card.Subtitle>Variance In Spend</Card.Subtitle>
                <h2 className="mb-3">{dollarPriceFormatter(variance)}</h2>
                {isMonthly ? (
                  <>
                    <Card.Subtitle>Last Month</Card.Subtitle>
                    <h3 className="mb-3">
                      {dollarPriceFormatter(getTotals(budgets, isMonthly, currentMonth - 1).variance)}
                    </h3>
                  </>
                ) : (
                  <>
                    <Card.Subtitle>Unallocated Budget</Card.Subtitle>
                    <h3 className="mb-3">{dollarPriceFormatter(getUnallocatedBudget(budgets))}</h3>
                    <Card.Subtitle>EOY Planned</Card.Subtitle>
                    <h3 className="mb-3">{dollarPriceFormatter(getEOYPlanned(budgets))}</h3>
                  </>
                )}
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default GaugeSummary
