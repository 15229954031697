import React from 'react'
import { Table } from 'react-bootstrap'

const ListUsers = ({ users }) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>email</th>
            <th>role</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            return (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.Username}</td>
                <td>{user?.Attributes?.find((attribute) => attribute.Name === 'email')?.Value}</td>
                <td>{user?.Attributes?.find((attribute) => attribute.Name === 'custom:role')?.Value}</td>
                <td>{user.UserStatus}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default ListUsers
