import React from 'react'
import AppContext from '../../../context/AppContext'
import getAllUsersUseCase from '../use-cases/get-all-users-use-case'
import InviteUser from './InviteUser'
import ListUsers from './ListUsers'
import { Alert } from 'react-bootstrap'

const User = (props) => {
  const { repoFactory } = React.useContext(AppContext)

  const [error, setError] = React.useState(false)
  const [users, setUsers] = React.useState([])

  const getAllUsers = React.useCallback(() => {
    getAllUsersUseCase({
      userRepo: repoFactory.userRepo(),
      observer: {
        receiveUsersInfo: (response) => {
          setUsers(response)
          setError(false)
        },
        errorWhileReceivingUsers: () => {
          setError(true)
        }
      }
    })
  }, [repoFactory, setUsers, setError])

  React.useEffect(() => {
    getAllUsers()
  }, [repoFactory, setUsers, setError, getAllUsers])

  return (
    <React.Fragment>
      {error && <Alert variant={'danger'}>Error retrieving users, please check and try again</Alert>}
      <InviteUser repoFactory={repoFactory} getAllUsers={getAllUsers} {...props} />
      <ListUsers users={users} {...props} />
    </React.Fragment>
  )
}

export default User
