import { Button } from 'react-bootstrap'
import React from 'react'
import { TimeCardStatus } from '../../constants/TimeCardStatus'

const ActionButtons = ({ isProductManager, isPortfolioManager, currentStatus, onAction, id }) => {
  const { buttonsAndStates, showButtons } = getButtonNamesAndStates({
    isProductManager,
    isPortfolioManager,
    currentStatus
  })

  return (
    showButtons && (
      <>
        <Button
          size="sm"
          className={buttonsAndStates?.accept?.class}
          onClick={() => onAction({ action: buttonsAndStates?.accept?.name, id })}
          disabled={buttonsAndStates?.accept?.disabled}
        >
          {buttonsAndStates?.accept?.name}
        </Button>
        <Button
          size="sm"
          className={`${buttonsAndStates?.reject?.class} red`}
          onClick={() => onAction({ action: buttonsAndStates?.reject?.name, id })}
          disabled={buttonsAndStates?.reject?.disabled}
        >
          {buttonsAndStates?.reject?.name}
        </Button>
      </>
    )
  )
}

export default ActionButtons

const getButtonNamesAndStates = ({ isProductManager, isPortfolioManager, currentStatus }) => {
  let showButtons = false
  const buttonsAndStates = {}

  if (currentStatus === 'Current') {
    return {
      showButtons: false
    }
  }

  if (isProductManager) {
    showButtons = true

    if ([TimeCardStatus.OPEN, TimeCardStatus.RESOLVE].includes(currentStatus)) {
      buttonsAndStates.accept = {
        disabled: false,
        name: TimeCardStatus.ACCEPT,
        class: 'green'
      }

      buttonsAndStates.reject = {
        disabled: false,
        name: TimeCardStatus.DISPUTE,
        class: 'red'
      }
    } else {
      buttonsAndStates.accept = {
        disabled: true,
        name: TimeCardStatus.ACCEPT,
        class: 'grey'
      }

      buttonsAndStates.reject = {
        disabled: true,
        name: TimeCardStatus.DISPUTE,
        class: 'grey'
      }
    }
  } else if (isPortfolioManager) {
    showButtons = true

    if (
      [TimeCardStatus.OPEN, TimeCardStatus.RESOLVE, TimeCardStatus.APPROVE, TimeCardStatus.REJECT].includes(
        currentStatus
      )
    ) {
      buttonsAndStates.accept = {
        disabled: true,
        name: TimeCardStatus.ACCEPT,
        class: 'grey'
      }

      buttonsAndStates.reject = {
        disabled: true,
        name: TimeCardStatus.REJECT,
        class: 'grey'
      }
    } else if ([TimeCardStatus.DISPUTE].includes(currentStatus)) {
      buttonsAndStates.accept = {
        disabled: false,
        name: TimeCardStatus.RESOLVE,
        class: 'green'
      }

      buttonsAndStates.reject = {
        disabled: false,
        name: TimeCardStatus.REJECT,
        class: 'red'
      }
    } else if ([TimeCardStatus.ACCEPT].includes(currentStatus)) {
      buttonsAndStates.accept = {
        disabled: false,
        name: TimeCardStatus.APPROVE,
        class: 'green'
      }

      buttonsAndStates.reject = {
        disabled: false,
        name: TimeCardStatus.REJECT,
        class: 'red'
      }
    }
  }

  return { buttonsAndStates, showButtons }
}
