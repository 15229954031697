import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import getAllBudgets from '../use-cases/getAllBudgets'
import BudgetsList from './BudgetsList'
import BudgetEntry from './BudgetEntry'
import './budgets.scss'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import RadioToggleSwitch from '../../components/common/RadioToggleSwitch'
import GaugeSummary from './GaugeSummary/GaugeSummary'
import Portfolios from './Portfolios/Portfolios'

const Budgets = () => {
  const { repoFactory, selectedYear } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [isGrouped, setIsGrouped] = useState(false)
  const [budgets, setBudgets] = useState([])
  const [budgetGroups, setBudgetsGroups] = useState([])
  const [selectedBudget, setSelectedBudget] = useState(null)
  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState(null)
  const [toggleOptions] = useState([
    {
      key: 'Monthly',
      value: 'monthly',
      label: 'Monthly'
    },
    {
      key: 'Annual',
      value: 'annual',
      label: 'Annual'
    }
  ])
  const [selectedToggle, setSelectedToggle] = useState('monthly')
  const [showToggle, setShowToggle] = useState(false)

  useEffect(() => {
    setShowToggle(selectedYear === new Date().getFullYear())
    setSelectedToggle('monthly')
  }, [selectedYear])

  useEffect(() => {
    getBudgets()
  }, [selectedYear, repoFactory])

  useEffect(() => {
    if (selectedBudgetGroup) {
      setBudgets(selectedBudgetGroup.items)
    }
  }, [selectedBudgetGroup])

  const isMonthly = selectedToggle === 'monthly'

  const getBudgets = () => {
    setIsLoading(true)
    setSelectedBudget(null)
    getAllBudgets(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken'),
        userRole: JSON.parse(localStorage.getItem('roles'))
      },
      {
        budgetsRepo: repoFactory.budgetsRepo(),
        observer: {
          errorReceivingBudgets: () => {
            setIsLoading(false)
            setIsGrouped(false)
            setBudgets([])
            setBudgetsGroups([])
          },
          receiveBudgets: ({ budgets, isGrouped }) => {
            setIsGrouped(isGrouped)
            setIsLoading(false)
            if (isGrouped) {
              setBudgetsGroups(budgets)
              setBudgets([])
            } else {
              setBudgetsGroups([])
              setBudgets(budgets)
            }
          }
        }
      }
    )
  }

  return (
    <div id="budgets">
      {isLoading && <LoadingSpinner />}

      <>
        {showToggle && (
          <RadioToggleSwitch
            toggleSwitchOptions={toggleOptions}
            selectedOption={selectedToggle}
            onChange={setSelectedToggle}
          />
        )}
        {(budgetGroups.length > 0 || budgets.length > 0) && (
          <GaugeSummary budgets={isGrouped ? budgetGroups : budgets} isMonthly={isMonthly} />
        )}
        {!isGrouped && budgets && (
          <BudgetsList budgets={budgets} setSelectedBudget={setSelectedBudget} isMonthly={isMonthly} />
        )}

        {budgetGroups && (
          <Portfolios
            budgets={budgetGroups}
            setSelectedBudget={setSelectedBudgetGroup}
            isGrouped={isGrouped}
            isMonthly={isMonthly}
          />
        )}
        {isGrouped && budgets && (
          <BudgetsList budgets={budgets} setSelectedBudget={setSelectedBudget} isMonthly={isMonthly} />
        )}
        {selectedBudget && <BudgetEntry selectedBudget={selectedBudget} getBudgets={getBudgets} />}
      </>
    </div>
  )
}

export default Budgets
