const postReview = ({ token, status, executionId }, { budgetsRepo, observer }) => {
  budgetsRepo.submitReview(
    { token, status, executionId },
    {
      success: (response) => {
        observer.reviewUpdated()
      },
      failure: () => {
        observer.errorWhileSubmittingReview()
      }
    }
  )
}

export default postReview
