import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap'
import inviteUserUseCase from '../use-cases/invite-user-use-case'
import Roles from '../../../config/Roles'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

const InviteUser = (props) => {
  const [createButtonDisabled, setCreateButtonDisabled] = React.useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null)
  const [roleOptions] = useState(() => {
    const roles = Object.keys(Roles)
    const options = []
    roles.forEach((el) => {
      options.push({
        key: el,
        value: Roles[el]
      })
    })
    return options
  })

  useEffect(() => {
    if (username && email && role) {
      setCreateButtonDisabled(false)
    } else {
      setCreateButtonDisabled(true)
    }
  }, [username, email, role])

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    inviteUserUseCase(
      {
        username,
        email,
        role
      },
      {
        userRepo: props.repoFactory.userRepo(),
        observer: {
          successfulInviteOfUser: () => {
            setError(false)
            setIsLoading(false)
            setUsername('')
            setEmail('')
            setRole(null)
            props.getAllUsers()
          },
          errorWhileInvitingUser: () => {
            setError(true)
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <Row>
      {error && <Alert variant={'danger'}>Error saving user, please check and try again</Alert>}

      {isLoading && <LoadingSpinner />}
      <Col md={12}>
        <Card className="mb-4">
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>username</Form.Label>
                <Form.Control
                  type="username"
                  placeholder="Enter a username for user"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  placeholder="Enter email for user"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Dropdown
                  onSelect={(eventKey) => {
                    setRole(JSON.parse(eventKey).value)
                  }}
                >
                  <Dropdown.Toggle variant="Primary">{role || 'Select a role'}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {roleOptions &&
                      roleOptions.map((el) => {
                        return (
                          <Dropdown.Item eventKey={JSON.stringify(el)} key={el.key}>
                            {el.value}
                          </Dropdown.Item>
                        )
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Button variant="primary" type="submit" disabled={createButtonDisabled} onClick={handleSubmit}>
                Invite User
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default InviteUser
