import assignPendingAndApprovedToAllocated from './converters/assignPendingAndApprovedToAllocated'
import Roles from '../../config/Roles'

const getAllBudgets = ({ year, token, userRole }, { observer, budgetsRepo }) => {
  budgetsRepo.getAllBudgets(
    { year, token },
    {
      success: (budgetsResponse) => {
        if (budgetsResponse.error) {
          observer.errorReceivingActuals()
          return
        }

        const isGrouped = !userRole.includes(Roles.PRODUCT_MANAGERS)

        const budgets = assignPendingAndApprovedToAllocated(budgetsResponse.data, userRole, isGrouped)
        observer.receiveBudgets({ budgets, isGrouped })
      },
      failure: () => {
        observer.errorReceivingBudgets()
      }
    }
  )
}

export default getAllBudgets
