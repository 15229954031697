const saveUserAccessUseCase = (
  { username, access, startDate, reference, allocationYear },
  { observer, userMapperRepo }
) => {
  userMapperRepo.saveUserAccess(
    {
      username,
      allocationYear,
      startDate,
      reference,
      access: access.map((item) => {
        const obj = {
          element1Id: item.element1
        }

        if (item.element2) {
          obj.element2Id = item.element2
        }

        if (item.element3) {
          obj.element3Id = item.element3
        }

        if (item.element4) {
          obj.element4Id = item.element4
        }

        return obj
      })
    },
    {
      success: (response) => {
        observer.saveUserAccessSuccessful(response)
      },
      failure: () => {
        observer.errorWhileSavingUserAccess()
      }
    }
  )
}

export default saveUserAccessUseCase
