import React from 'react'
import Roles from '../../../config/Roles'
import TimeCardsTable from './TimeCardsTable'
import TimeCards from './TimeCards'

const CloudSpendCards = ({ timeCards, inputMonth, timeCardTotals, getUsageStats, budgetsData, selectedToggle }) => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const isProductManager = roles.includes(Roles.PRODUCT_MANAGERS)
  const isPortfolioManager = roles.includes(Roles.PORTFOLIO_MANAGERS)

  const isActionableUser = isPortfolioManager || isProductManager

  return (
    timeCards &&
    timeCards.length > 0 && (
      <>
        <h2 className="mt-4">Cloud Spend Cards</h2>
        {isActionableUser ? (
          <TimeCardsTable
            isActionableUser={isActionableUser}
            isProductManager={isProductManager}
            isPortfolioManager={isPortfolioManager}
            inputMonth={inputMonth}
            timeCards={timeCards}
            timeCardTotals={timeCardTotals}
            getUsageStats={getUsageStats}
            budgetsData={budgetsData}
            selectedToggle={selectedToggle}
          />
        ) : (
          <TimeCards
            timeCardTotals={timeCardTotals}
            timeCards={timeCards}
            inputMonth={inputMonth}
            selectedToggle={selectedToggle}
          />
        )}
      </>
    )
  )
}

export default CloudSpendCards
