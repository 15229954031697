const priceParser = (input, withDecimalLength = 16, withoutDecimalLength = 15) => {
  if (!input) return ''

  input = input.match(/[\d.]/g)
  if (input === null) return ''
  input = input.join('')

  if (input.includes('.')) {
    if (input.charAt(0) === '.') {
      input = '0' + input
    }

    let decimal = input.match(/\d+\.\d*/)[0]

    if (parseFloat(decimal) < 1) {
      return decimal.substr(0, 6)
    }

    const match = decimal.match(/(\d+)\.(\d*)/)
    decimal = match[1] + '.' + match[2].substr(0, 2)

    return decimal.substr(0, withDecimalLength)
  }

  return input.substr(0, withoutDecimalLength)
}

export default priceParser
