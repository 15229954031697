const getAccountsMap = ({ observer, accountMapperRepo }) => {
  accountMapperRepo.findAllAccounts({
    success: (accounts) => {
      const response = {}
      accounts.forEach((account) => {
        response[account.accountId] = {
          id: account.id,
          accountName: account.accountName
        }
      })

      observer.receiveAccounts(response)
    },
    failure: (e) => {
      observer.errorReceivingAccounts()
    }
  })
}

export default getAccountsMap
