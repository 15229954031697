import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { sortBy } from 'lodash'

const columnHelper = createColumnHelper()

const getWeeklyAllocationReportColumns = (currentTimeRanges, previousTimeRanges) => {
  const currentColumns = sortBy(currentTimeRanges, ['week']).map((item) => {
    return columnHelper.accessor((row) => row.breakdowns.find((it) => it.dateRange === item.dateRange)?.total, {
      id: item.dateRange,
      cell: (info) => dollarPriceFormatter(info.getValue()),
      aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
      aggregationFn: 'sum'
    })
  })

  const previousColumns = sortBy(previousTimeRanges, ['week']).map((item) => {
    return columnHelper.accessor((row) => row.breakdowns.find((it) => it.dateRange === item.dateRange)?.total, {
      id: item.dateRange,
      cell: (info) => dollarPriceFormatter(info.getValue()),
      aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
      aggregationFn: 'sum'
    })
  })
  return [
    columnHelper.group({
      header: 'Financial Scope',
      columns: [
        columnHelper.group({
          header: '-',
          columns: [
            columnHelper.accessor('element4', {
              header: () => 'Product'
            }),
            columnHelper.accessor('accountId', {
              header: () => 'Account'
            }),
            columnHelper.accessor('percentage', {
              header: () => 'Allocation',
              aggregatedCell: () => ''
            })
          ]
        })
      ]
    }),
    columnHelper.group({
      header: 'Period',
      columns: [
        columnHelper.group({
          id: 'Current',
          header: () => 'Current',
          columns: currentColumns
        }),

        columnHelper.group({
          header: 'Previous',
          columns: previousColumns
        })
      ]
    })
  ]
}

export default getWeeklyAllocationReportColumns
