import React, { useEffect, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import changePasswordChallengeUseCase from '../use-cases/change-password-challenge-use-case'
import moment from 'moment'
import Routes from '../../routes'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from '../../components/common/LoadingSpinner'

const UpdatePassword = ({ username, session, repoFactory }) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [changePasswordButtonDisabled, setChangePasswordButtonDisabled] = useState(true)
  const [challengePassword, setChallengePassword] = useState(null)
  const [verifyChallengePassword, setVerifyChallengePassword] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (challengePassword || verifyChallengePassword) {
      setChangePasswordButtonDisabled(challengePassword !== verifyChallengePassword)
    }
  }, [challengePassword, verifyChallengePassword, setChangePasswordButtonDisabled])

  const handleChallengePassword = (e) => {
    e.preventDefault()
    setIsLoading(true)
    changePasswordChallengeUseCase(
      {
        username,
        password: challengePassword,
        session
      },
      {
        authRepo: repoFactory.authRepo(),
        observer: {
          changePasswordSuccess: ({ authToken, refreshToken, expires, role }) => {
            setError(false)
            localStorage.setItem('authToken', authToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('expirationTime', JSON.stringify(moment.now() + expires * 1000))
            localStorage.setItem('roles', JSON.stringify([role]))
            setIsLoading(false)
            history.push(Routes.DASHBOARD.PATH)
          },
          changePasswordFailed: () => {
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  return (
    <>
      {error && <Alert variant={'danger'}>Unable to change password, please validate new password and try again</Alert>}
      {isLoading && <LoadingSpinner />}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter a new password"
            onChange={(e) => {
              setChallengePassword(e.target.value)
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Re-enter Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Verify new password"
            onChange={(e) => {
              setVerifyChallengePassword(e.target.value)
            }}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={changePasswordButtonDisabled || isLoading}
          onClick={handleChallengePassword}
        >
          Change Password
        </Button>
      </Form>
    </>
  )
}

export default UpdatePassword
