import React from 'react'
import moment from 'moment'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Routes from '../../routes'
import Avatar from 'react-avatar'

import logo from './logo.svg'
import './nav.scss'

const TopNav = ({ isAuthenticated, lastRunTime }) => {
  const userName = localStorage.getItem('username')

  return (
    <header className="sticky-top">
      <Navbar className="top-nav">
        <Container>
          <Navbar.Brand href={Routes.DASHBOARD.PATH}>
            <img alt={Routes.DASHBOARD.TITLE} src={logo} width="200" />
          </Navbar.Brand>
          <Navbar.Toggle />
          {isAuthenticated && (
            <div className="display">
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text className="info-avatar">
                  <span>Welcome {userName}</span>
                  <span>
                    {lastRunTime && `AWS spend as of ${moment.utc(lastRunTime).local().format('MM/DD/yyyy hh:mm A')}`}
                  </span>
                </Navbar.Text>

                <NavDropdown title={<Avatar name={userName} size="50" round={true} />} className="nav-menu">
                  <NavDropdown.Item href="/">Profile</NavDropdown.Item>
                  <NavDropdown.Item href="/">Another action</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <Nav.Item key={Routes.LOGOUT.PATH} className="dropdown-item">
                    <Link to={Routes.LOGOUT.PATH}>Logout</Link>
                  </Nav.Item>
                  {/*<NavDropdown.Item to={Routes.LOGOUT.PATH}>Logout</NavDropdown.Item>*/}
                </NavDropdown>
              </Navbar.Collapse>
            </div>
          )}
        </Container>
      </Navbar>
    </header>
  )
}

export default TopNav
