const twoDecimalsFormatter = (num, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  if (num === '' || num === null || isNaN(num)) {
    return '--'
  }

  const formattedPrice = Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits
  }).format(num)

  return formattedPrice === '-0.00' ? '0.00' : formattedPrice
}

export default twoDecimalsFormatter
