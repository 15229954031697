import React, { useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import UpdatePassword from './UpdatePassword'
import UsernamePassword from './UsernamePassword'

function Login({ repoFactory }) {
  const [changePasswordChallenge, setChangePasswordChallenge] = useState(false)
  const [username, setUsername] = useState(null)
  const [challengePasswordSession, setChallengePasswordSession] = useState(null)

  return (
    <Container fluid>
      <Row className="justify-content-md-center pt-5">
        <Col xs={12} md={9} xl={6}>
          {changePasswordChallenge ? (
            <UpdatePassword username={username} session={challengePasswordSession} repoFactory={repoFactory} />
          ) : (
            <UsernamePassword
              username={username}
              setUsername={setUsername}
              setChangePasswordChallenge={setChangePasswordChallenge}
              setChallengePasswordSession={setChallengePasswordSession}
              repoFactory={repoFactory}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Login
