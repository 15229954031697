import assignPendingAndApprovedToAllocated from './converters/assignPendingAndApprovedToAllocated'
import Roles from '../../config/Roles'

const getBudgetsMap = ({ year, token, userRole }, { observer, budgetsRepo }) => {
  budgetsRepo.getAllBudgets(
    { year, token },
    {
      success: (budgetsResponse) => {
        if (budgetsResponse.error) {
          observer.errorReceivingActuals()
          return
        }

        const isGrouped = !userRole.includes(Roles.PRODUCT_MANAGERS)

        const budgets = assignPendingAndApprovedToAllocated(budgetsResponse.data, userRole, isGrouped)
        const response = {}
        budgets.forEach((budget) => {
          response[budget.element4] = {
            id: budget.element4,
            budgets: budget.budgets
          }
        })
        observer.receiveBudgets(response)
      },
      failure: () => {
        observer.errorReceivingBudgets()
      }
    }
  )
}

export default getBudgetsMap
