import React from 'react'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import { Alert, Button, Card } from 'react-bootstrap'
import Accounts from './Accounts'
import AccountsWithBudgets from './AccountsWithBudgets'
import listAccountMappings from '../use-cases/list-account-mappings'
import reFetchAllAccounts from '../use-cases/re-fetch-all-accounts'

const AllAccountMappingDetails = (props) => {
  const { repoFactory, sorElements, selectedYear } = React.useContext(AppContext)
  const [isLoading, setIsLoading] = React.useState(true)
  const [allAccounts, setAllAccounts] = React.useState([])

  const [accountsWithAllocations, setAccountsWithAllocations] = React.useState(null)
  const [listAllocationsError, setListAllocationsError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [selectedAccountForRemap, setSelectedAccountForRemap] = React.useState(null)

  const getAccountMapping = React.useCallback(() => {
    listAccountMappings(
      {
        allocationYear: selectedYear
      },
      {
        accountMapperRepoNew: repoFactory.accountMapperRepoNew(),
        observer: {
          elementsReceived: (elements) => {
            setListAllocationsError(false)
            setAccountsWithAllocations(elements.existingMappings)
          },
          noElementsReceived: () => {
            setListAllocationsError(false)
            setAccountsWithAllocations([])
          },
          errorGettingData: () => {
            setListAllocationsError(true)
            setAccountsWithAllocations(null)
          }
        }
      }
    )
  }, [repoFactory, selectedYear])

  React.useEffect(() => {
    getAccountMapping()
  }, [repoFactory, setListAllocationsError, selectedYear, getAccountMapping])

  const onSuccessfulAllocationChange = () => {
    getAccountMapping()
  }

  const onRemap = (item) => {
    setSelectedAccountForRemap(item)
  }

  const onForceFetch = () => {
    reFetchAllAccounts({
      accountMapperRepo: repoFactory.accountMapperRepoNew(),
      observer: {
        successfullyReFetchingAccounts: () => {
          getAccountMapping()
        },
        errorReFetchingAccounts: () => {}
      }
    })
  }

  return (
    <div className="account-mapper">
      {listAllocationsError && <Alert variant="danger">Error getting account mappings</Alert>}

      {sorElements && (
        <div>
          {isLoading && <LoadingSpinner />}
          {error && <Alert variant="danger">Error getting accounts</Alert>}
          {accountsWithAllocations && (
            <>
              <Button className="mb-3" onClick={onForceFetch}>
                Force fetch accounts
              </Button>

              <Accounts
                setIsLoading={setIsLoading}
                setError={setError}
                existingAllocations={accountsWithAllocations}
                onSuccessfulAllocationChange={onSuccessfulAllocationChange}
                selectedAccountForRemap={selectedAccountForRemap}
                allocationYear={selectedYear}
                setAllAccounts={setAllAccounts}
                {...props}
              />
            </>
          )}
          {accountsWithAllocations?.length > 0 ? (
            <AccountsWithBudgets
              allAccounts={allAccounts}
              selectedYear={selectedYear}
              repoFactory={repoFactory}
              sorElements={sorElements}
              accountsWithAllocations={accountsWithAllocations}
              allocationYear={selectedYear}
              onRemap={onRemap}
            />
          ) : (
            <>
              <Card className="mt-lg-4">
                <Card.Body>No accounts are mapped to budgets.</Card.Body>
              </Card>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default AllAccountMappingDetails
