const listAccountMappings = ({ allocationYear }, { observer, accountMapperRepoNew }) => {
  accountMapperRepoNew.listAccountMapping(allocationYear, {
    success: (response) => {
      if (response.listAccountMapping.error) {
        observer.errorGettingData()
        return
      }

      if (!response.listAccountMapping.existingMappings) {
        observer.noElementsReceived()
        return
      }

      observer.elementsReceived({
        existingMappings: response.listAccountMapping.existingMappings
      })
    },
    failure: () => {
      observer.errorGettingData()
    }
  })
}

export default listAccountMappings
